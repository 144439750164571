import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ICopilotMessageCOE,
  ICopilotMessageVM,
} from "../../../utils/interfaces/ICopilot";
import { IBase, IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const usePostCopilotChatMessage = () => {
  return useMutation<IBase<ICopilotMessage[]>, Error, ICopilotMessageCOE>({
    mutationFn: (data) => makeRequest.post(`/Copilot/chat`, { ...data }),
  });
};
