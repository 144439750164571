import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import Introduce from "./helperCards/Introduce";
import NextStep from "./helperCards/NextStep";
import PostIdea from "./helperCards/PostIdea";
import { useGetRandomPostIdea } from "../../api/brand/postIdeas/random/get";
import { useGetOnboardingInfo } from "../../api/workspaces/onboardingInfo/get";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";
function HelperCardWrapper({ ...props }) {
  const { data: postIdea } = useGetRandomPostIdea();
  const { data: onboardingInfo, isLoading: isOnboardingInfoLoading } =
    useGetOnboardingInfo();
  const [flowCompleted, setFlowCompleted] = useState(false);
  useEffect(() => {
    if (onboardingInfo) {
      Object.values(onboardingInfo.data).forEach((value) => {
        if (value) setProgressBarPercentage(progressBarPercentage + 20);
      });
      const allStepsCompleted = Object.values(onboardingInfo.data).every(
        Boolean,
      );
      setFlowCompleted(allStepsCompleted);
    }
  }, [onboardingInfo]);
  const [isOpen, setIsOpen] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(20);
  const { personalInfo } = useAccountStore();
  return (
    <div className="flex flex-col items-center">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="h-xl:hidden flex w-full justify-center items-center gap-[10px] pt-[12px] mb-[12px]"
      >
        <p className="text-sm-sb text-brand-700">Tips And Tutorials</p>
        <FontAwesomeIcon
          className="text-[16px] text-brand-700"
          icon={isOpen ? faChevronDown : faChevronUp}
        />
      </div>
      <div
        className={`
          ${isOpen ? "flex absolute bottom-[150px] max-sm:bottom-[150px] bg-base-white rounded-[8px] border border-gray-200 shadow-xl py-[4px] px-[4px] !w-[90%]" : "hidden"}
          h-xl:flex w-full flex-col items-center px-[16px] mb-[12px]`}
      >
        <Swiper
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={100}
          loop={true}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Introduce />
          </SwiperSlide>
          {postIdea &&
            personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
              <SwiperSlide>
                <PostIdea idea={postIdea.data.idea} />
              </SwiperSlide>
            )}
          {!flowCompleted && (
            <SwiperSlide>
              <NextStep
                progressBarPercentage={progressBarPercentage}
                isOnboardingInfoLoading={isOnboardingInfoLoading}
                onboardingInfo={onboardingInfo}
              />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  );
}

export default HelperCardWrapper;
