import { useRef, useState } from "react";
import LibraryTab from "./Media/LibraryTab";
import { useCreationStore } from "../../../store/creationStore";
import HelperInput from "./Media/HelperInput/HelperInput";
import { LibraryDropdown } from "../../Library/LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useUploadAssets } from "../../Library/hooks/useUploadAssets";
import { useLibraryDropbox } from "../../Library/hooks/useLibraryDropbox";
import { useFolderHistory } from "../../Library/hooks/useFolderHistory";
import { LibraryCreateFolderModal } from "../../Library/LibraryCreateFolderModal";
import { useCreationLibraryHelperFunctions } from "./Media/hooks/useCreationLibraryHelperFunctions";
import { toast } from "../../../hooks/toast";
import { useGridCardActions } from "../../Library/hooks/useGridCardActions";
import { getQuery } from "../../../hooks/functions/queries";
import { ConfirmDelete } from "../../Library/ConfirmDelete";
function Media() {
  const wrapperRef = useRef<any>();
  const { librarySearchValue } = useCreationStore();
  const {
    parentFolderId,
    isFetching,
  } = useFolderHistory({
    wrapperRef,
    searchValue: librarySearchValue.length > 0 ? librarySearchValue : undefined,
  });
  const [folderName, setFolderName] = useState("");
  const { selectUploadedMedia } = useCreationLibraryHelperFunctions();

  const { selectFile, createFolder, isPending, createFile } =
    useUploadAssets({
      parentFolderId,
      uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
    });
  const { dropboxRef, onDropboxSelectSubmited } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      uploadFileAdditionalFunction: (res) => selectUploadedMedia(res),
    });

  const [openModal, setOpenModal] = useState(false);
  const {
    openRename,
    setOpenRename,
    updateFolder,
    setValue,
    value,
    handleDownload,
    deleteFile,
    deleteFolder,
    setOpenConfirmation,
    openConfirmation,
    handleDelete,
    handleMoveInto,
    handleMoveOut,
    handleFolderDownload,
    handleRename,
  } = useGridCardActions({});

  return (
    <>
      {openModal && (
        <LibraryCreateFolderModal
          openModal={openModal}
          action="Create"
          type="Folder"
          setOpenModal={() => setOpenModal(false)}
          name={folderName}
          setName={(e) => setFolderName(e.target!.value)}
          onSave={() => {
            createFolder({ name: folderName, parentFolderId }).catch((err) => {
              toast(
                err?.response?.data?.message ||
                  "An error occurred while creating the folder"
              );
            });
            setOpenModal(false);
            setFolderName("");
          }}
        />
      )}

      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type={"Folder"}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          name={value}
          setName={(e) => setValue(e.target!.value)}
          onSave={() => {
            updateFolder({
              folderName: value,
              id: parseInt(getQuery("id") || "0"),
            })
              .then(() => {
                toast(`Folder renamed successfully`, "success");
              })
              .catch(() => {
                toast(`Folder rename failed`, "error");
              });
            setOpenRename(false);
            setValue("");
          }}
        />
      )}

      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() => {
            getQuery("type") === "File" &&
              deleteFile({ id: parseInt(getQuery("id") || "") })
                .then(() => {
                  toast(`Asset deleted successfully`, "success");
                })
                .catch((err) => {
                  toast(err.response.data.message, "error");
                });
            getQuery("type") === "Folder" &&
              deleteFolder({ id: parseInt(getQuery("id") || "") })
                .then(() => {
                  toast(`Asset deleted successfully`, "success");
                })
                .catch((err) => {
                  toast(err.response.data.message, "error");
                });
            setOpenConfirmation(false);
          }}
        />
      )}

      <div style={{ height: "inherit" }} className="relative">
        <div className="min-h-[calc(46px+17px)] flex justify-end items-center border-b-[1px] border-b-graycool-100 pb-[16px]">
          <HelperInput />
          <div className={"h-[32px] w-[1px] bg-gray-200 mr-[12px]"}></div>
          <LibraryDropdown
            btnClassName="relative z-[10]"
            handleFiles={() => selectFile({ multiple: true })}
            handleFolder={() => setOpenModal(true)}
            handleDropbox={() => dropboxRef.current?.click()}
          />
          <DropboxChooser
            appKey="js3i72s02ssirnl"
            success={(files: any) => {
              onDropboxSelectSubmited(files);
            }}
            extensions={[
              ".jpg",
              ".jpeg",
              ".png",
              ".mp4",
              ".mov",
              ".avi",
              ".wmv",
              ".flv",
              ".webm",
              ".pdf",
              ".docx",
              ".doc",
              ".xlsx",
              ".xls",
              ".txt",
            ]}
            multiselect={true}
          >
            <input ref={dropboxRef} className="hidden" />
          </DropboxChooser>
        </div>
        <LibraryTab
          wrapperRef={wrapperRef}
          isFetching={isFetching}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFolderDownload={handleFolderDownload}
          handleMoveInto={handleMoveInto}
          handleRename={handleRename}
          handleMoveOut={handleMoveOut}
          librarySearchValue={librarySearchValue}
          setOpenModal={setOpenModal}
          dropboxRef={dropboxRef}
          selectFile={selectFile}
        />
      </div>
    </>
  );
}

export default Media;
