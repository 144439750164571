import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ITokenVM } from "../../../utils/interfaces/IToken";
import { useCopilotStore } from "../../../store/copilotStore";

export const usePutSwitchTeam = () => {
  const queryClient = useQueryClient();
  const { reset: resetCopilotStore } = useCopilotStore();
  return useMutation<IBaseData<ITokenVM>, any, { teamId: number }>({
    mutationFn: ({ teamId }) =>
      makeRequest.put(`/Auth/switch-workspace/${teamId}`),
    onSuccess: (res) => {
      const queriesToInvalidate = [
        "useGetAllBrands",
        "useGetActiveBrand",
        "useGetCurrentBrand",
        "useGetInvitesForTeam",
        "useGetAnalytic",
        "useGetPendingInvitesForTeam",
        "useGetUser",
        "useGetAllCreation",
        "useGetCurrentTeam",
        "useGetTeamsLimits",
        "useGetCurrentPackage",
        "useGetLastPackage",
        "useGetCurrentPeriodInfo",
        "useGetAllFiles",
        "useGetFoldersAndFiles",
        "useGetCalendarData",
        "useGetOnboardingInfo",
        "useGetActiveMembers"
      ];

      const nullResponseQueries = [
        "useGetAllConnections",
        "useGetScheduledPosts",
        "useGetInvoices",
        "useGetHavePaymentMethod",
        "useGetFolders",
        "useGetFolderFiles",
        "useGetCopilotSessions",
        "useGetBrandingPost",
        "useGetPendingInvitesForTeam",
        "useGetBrand",
        "useGetCurrentWorkspace"
      ];

      queriesToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      });

      nullResponseQueries.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] }).then((r) => null);
      });

      resetCopilotStore();
      localStorage.setItem("token", res.data.accessToken);
      return res;
    },
  });
};
