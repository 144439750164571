import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

export const RestrictExpiredTooltip = ({
  title = "You have reached the limit of uploading files for your workspace.",
  available
}: {
  title?: string;
  available: boolean;
}) => {;
  const randomId = "create-new-post-tooltip" + Math.random();
  return (
    <>
      {!available && (
        <>
          <FontAwesomeIcon
            className="text-gray-500"
            size="sm"
            data-tooltip-id={randomId}
            icon={faCircleQuestion}
          />
          <Tooltip
            id={randomId}
            place="top"
            style={{ zIndex: 100 }}
            disableStyleInjection
            className="text-sm"
            children={title}
          />
        </>
      )}
    </>
  );
};
