import { useEffect, useState } from "react";

export const useCheckHasScroll = (ref: any) => {
  const [hasScroll, setHasScroll] = useState(false);
  const checkScroll = () => {
    const element = ref.current;
    if (element) {
      setHasScroll(element.scrollHeight > element.clientHeight);
    }
  };
  useEffect(() => {
    const element = ref.current;
    if (element) {
      checkScroll();
      const resizeObserver = new ResizeObserver(checkScroll);
      resizeObserver.observe(element);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [checkScroll]);

  return { hasScroll };
};
