import React from "react";
import ImageSelectorItem from "./ImageSelectorItem";

function ImageSelectorSidebar() {
  return (
    <div className="max-w-[224px] min-w-[224px] pt-[20px] w-full bg-graycool-50 border-r border-r-gray-200">
      <p className="text-sm-sb text-gray-800 px-[36px]">Select image</p>
      <div
        className="flex flex-col gap-[24px] mt-[16px] pb-[20px] overflow-auto items-center"
        style={{ height: "calc(100% - 32px)" }}
      >
        <ImageSelectorItem frameImage="https://t4.ftcdn.net/jpg/01/77/47/67/360_F_177476718_VWfYMWCzK32bfPI308wZljGHvAUYSJcn.jpg" />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
        <ImageSelectorItem />
      </div>
    </div>
  );
}

export default ImageSelectorSidebar;
