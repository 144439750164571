import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import LibraryView from "../components/Library/LibraryView";
import { ISingleFoldersAndFilesVM } from "../utils/interfaces/IAssets";
import { IHandleDeleteProps, IHandleDownloadProps, IHandleFolderDownloadProps, IHandleMoveIntoProps, IHandleMoveOutProps, IHandleRenameProps } from "../components/Library/hooks/useGridCardActions";

export type CardType = "Folder" | "Image" | "PDF" | "Video" | "Word" | "Text";

export interface ILibraryProps {
  id: number;
  cardType: CardType;
  extension?: string;
  pathInStorage?: string;
  object?: ISingleFoldersAndFilesVM;
  cardTitle: string;
  imageSrc?: string;
  mediumImageSrc?: string;
  isSelected?: boolean;
  rawTitle?: string;
  className?: string;
  filesCount?: number;
  isRoot?: boolean;
  fileSize?: number;
  hasOptions?: boolean;
  parentFolderId?: number | null;
  isContent?: boolean;
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setOpenConfirmation?: Dispatch<SetStateAction<boolean>>;
  handleMoveInto?: (values: IHandleMoveIntoProps) => void;
  setPage?: Dispatch<SetStateAction<number>>;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onDoubleClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onDelete?: () => void;
  onEdit?: () => void;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  handleRename?: (values: IHandleRenameProps) => void;
  handleDelete?: (values: IHandleDeleteProps) => void;
  handleMoveOut?: (values: IHandleMoveOutProps) => void;
  handleDownload?: (values: IHandleDownloadProps) => void;
  handleFolderDownload?: (values: IHandleFolderDownloadProps) => void;
}

function Library() {
  return (
    <div>
      {/* <Sidebar /> */}
      <LibraryView />
    </div>
  );
}

export default Library;
