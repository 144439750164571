import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import {
  ICopilotSessionVM,
  ICopilotVM,
} from "../../../utils/interfaces/ICopilot";
import { CopilotType } from "../../../utils/enums/Enums";
import { makeRequest } from "../../auth/axios";

export const useGetCopilotSession = ({
  type = CopilotType.DEFAULT,
  enabled = true,
  postId,
}: {
  postId?: number;
  type: CopilotType;
  enabled?: boolean;
}) => {
  return useQuery<IBaseData<ICopilotSessionVM[]>>({
    queryKey: ["useGetCopilotSessions"],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/Copilot?type=${type}${postId ? `&postId=${postId}` : ""}`
      );
      return response as IBaseData<ICopilotSessionVM[]>;
    },
    retry: 0,
    enabled: enabled && (type !== CopilotType.POST || !!postId),
  });
};
