import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { CopilotType } from "../../../utils/enums/Enums";

export const usePostCopilotSession = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ICopilotSessionVM>,
    any,
    { type: CopilotType; postId?: number }
  >({
    mutationFn: ({ type = CopilotType.DEFAULT, postId }) => {
      let url = `/Copilot?type=${type}`;
      if (postId !== undefined) {
        url += `&postId=${postId}`;
      }
      return makeRequest.post(url);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCopilotSessions"] });
    },
  });
};
