import PostCard from "./PostCard";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import ContentSkeleton from "../Skeletons/ContentSkeleton";
//@ts-ignore
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import SButton from "../../design-system/SButton";
import { EmptyState } from "../Library/EmptyState";
import { ViewerRestrictTooltip } from "../utils/ViewerRestrictTooltip";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";
import { toast } from "../../hooks/toast";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";

function PostList({
  posts,
  isLoading,
  handleOpenPost,
  handleCreateNew,
  searchValue,
}: {
  handleOpenPost: (p: ISinglePostVM) => void;
  isLoading: boolean;
  posts: ISinglePostVM[];
  handleCreateNew: () => void;
  searchValue: string;
}) {
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  return (
    <>
      <div className="mt-[32px] mb-[40px]">
        <ResponsiveMasonry
          columnsCountBreakPoints={{
            350: 1,
            900: 2,
            1200: 3,
            1440: 4,
            1960: 5,
            2200: 6,
          }}
        >
          <Masonry gutter="12px">
            {isLoading && (
              <>
                <ContentSkeleton />
              </>
            )}
            {posts.length > 0 &&
              posts.map((item, i) => <PostCard post={item} key={i} />)}
            {posts.length === 0 && !isLoading && (
              <EmptyState
                className="mt-16"
                title={"No Posts Found "}
                subtitle={"Create post and get started"}
                searchValue={searchValue}
                actionButton={
                  <SButton
                    className="min-w-[104px]"
                    onClick={() => {
                      if (!workspaceUsage?.data.canUserAddPosts) {
                        toast("You have reached the limit of posts for your workspace.","error");
                        return;
                      }
                      handleCreateNew();
                    }}
                    disabled={
                      personalInfo?.userRole === OrganizationUserRole.VIEWER
                    }
                  >
                    Create new
                    <ViewerRestrictTooltip />
                  </SButton>
                }
              />
            )}
          </Masonry>
        </ResponsiveMasonry>
        {/* <PostCard /> */}
        {/* {posts.map((item) => (
          <PostCard
            postName={item.name}
            postId={item.id}
            status={item.socialNetworkPosts[0].postStatus}
            usersItem={assigneUsersData}
            assignedIndex={0}
            handleClick={() => handleOpenPost(item)}
            socialNetworks={[1, 2]}
          />
        ))} */}
      </div>
    </>
  );
}

export default PostList;
