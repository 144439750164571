import { useEffect } from "react";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import { useCopilotStore } from "../../../store/copilotStore";
import { useGetCopilotSession } from "../../../api/copilot/session/get";
import { CopilotType } from "../../../utils/enums/Enums";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useFillChat = () => {
  const {
    data: sessions,
    refetch,
    isFetched,
  } = useGetCopilotSession({
    type: CopilotType.DEFAULT,
  });

  const { messages, setMessages, activeSession, setActiveSession } =
    useCopilotStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: activeSession?.id,
  });
  useEffect(() => {
    if (!activeSession) {
      refetch().then((res) => {
        //@ts-ignore
        setActiveSession(res.data?.data[0]);
      });
    }
    // if (!activeSession && sessions && sessions.data.length > 0 && isFetched) {
    //   setActiveSession(sessions.data[0]);
    // }
  }, [sessions, activeSession, isFetched]);

  useEffect(() => {
    if (activeSession) {
      refetchCopilotMessages().then((res) => {
        let tmp: ICopilotMessage[] = [];
        // @ts-ignore
        // TODO: MOVXSNA KOMENTARI
        res!.data!.data.map((item) => {
          tmp.push({
            role: item.role,
            content: item.content,
            files: item.files,
          });
        });
        setMessages(tmp);
      });
    }
  }, [activeSession]);
};
