import {
  faArrowRightFromBracket,
  faBars,
  faBell,
  faBuildings,
  faCommentSmile,
  faCreditCard,
  faLayerGroup,
  faPlus,
  faQuestionCircle,
  faSparkles,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import TypewriterEffect from "./TypewriterEffect";
import SButton from "../../design-system/SButton";
import { useCopilotStore } from "../../store/copilotStore";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { useGetUser } from "../../hooks/useUserInfo";
import MobileDrawer from "./MobileDrawer";
import NotificationsInHeader from "./NotificationsInHeader";
import { useGetCurrentBrand } from "../../api/brand/current/get";
import { Tooltip } from "react-tooltip";
import { ViewerRestrictTooltip } from "../utils/ViewerRestrictTooltip";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { toast } from "../../hooks/toast";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";

function Header() {
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const { setIsCopilotOpen } = useCopilotStore();
  const { data: userInfo } = useGetUser();
  const { data: workspaceUsage } = useGetWorkspaceUsage();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    queryClient.resetQueries();
    navigate("/login");
  };
  const { handleOpenPost } = useCreationHelperFunctions();

  return (
    <div className="flex justify-center sticky right-0 top-[12px] mt-[12px] z-[10]">
      <MobileDrawer
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />
      <div
        // style={
        //   {
        //     boxShadow:
        //       "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(152, 162, 179, 0.14)",
        //   }
        // }
        className={`
        border border-gray-200
        px-[6px] z-[98] flex justify-between items-center py-[6px]
        w-[calc(100%-64px)] h-[50px] bg-base-white rounded-[12px] gap-[16px] max-sm:w-[calc(100%-16px)]
      `}
      >
        <SButton
          onClick={() => setIsMobileMenuOpen(true)}
          icon={faBars}
          size="sm"
          type="secondaryGray"
          className={"sm:hidden"}
        />
        {!isCurrentBrandError && !currentBrand?.data?.isBrandDataGenerated && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Please wait while we generate your brand
          </Tooltip>
        )}
        {isCurrentBrandError && (
          <Tooltip
            style={{
              fontSize: "14px",
            }}
            id="tooltip-header-copilot"
            place="top"
            disableStyleInjection
          >
            Create brand to use STORI Assistant
          </Tooltip>
        )}
        <div
          data-tooltip-id="tooltip-header-copilot"
          onClick={() => {
            if (
              !isCurrentBrandError &&
              currentBrand?.data.isBrandDataGenerated
            ) {
              setIsCopilotOpen(true);
            }
          }}
          className="relative h-full cursor-text header-input-border-gradient flex-grow max-w-[320px] flex justify-between items-center px-[12px]"
        >
          <TypewriterEffect />
          <FontAwesomeIcon
            icon={faSparkles}
            className="text-[14px] text-brand-600"
          />
        </div>
        <div className="flex items-center gap-[16px]">
          {isCurrentBrandError ? (
            <SButton
              onClick={() => navigate("/create-brand")}
              lIcon={faPlus}
              size="sm"
              type="secondaryColor"
              className="max-sm:hidden"
            >
              Create Brand
            </SButton>
          ) : (
            <SButton
              onClick={() => {
                if (!workspaceUsage?.data.canUserAddPosts) {
                  toast("You have reached the limit of posts for your workspace.","error");
                  return;
                }
                handleOpenPost({});
              }}
              lIcon={faPlus}
              size="sm"
              type="secondaryColor"
              className="max-sm:hidden"
              disabled={
                userInfo?.data?.data.userRole === OrganizationUserRole.VIEWER
              }
            >
              Create Post
              <ViewerRestrictTooltip />
            </SButton>
          )}
          <NotificationsInHeader />
          <img
            id="header-profile-image"
            onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
            src={
              userInfo?.data.data.photoUrl ??
              "https://cdn.storiai.com/avatar.svg"
            }
            alt="avatar"
            className="cursor-pointer w-[36px] h-[36px] object-cover rounded-full"
          />
          {isProfileMenuOpen && (
            <SSmallMenu
              isOpen={isProfileMenuOpen}
              className="right-[32px] left-[unset] w-fit"
              setIsOpen={(p) => setIsProfileMenuOpen(p)}
              excludedId="header-profile-image"
              data={[
                [
                  {
                    text: "View Profile",
                    icon: faUser,
                    handleClick: () => navigate("/account"),
                  },
                  {
                    text: "Workspace",
                    icon: faBuildings,
                    handleClick: () => navigate("/workspace?section=overview"),
                  },
                  ...(userInfo?.data.data.userRole ===
                  OrganizationUserRole.VIEWER
                    ? []
                    : [
                        {
                          text: "Billing",
                          icon: faCreditCard,
                          handleClick: () =>
                            navigate("/workspace?section=billing"),
                        },
                      ]),
                ],
                [
                  {
                    text: "What's New",
                    icon: faSparkles,
                    handleClick: () =>
                      window.open("https://stori.canny.io/changelog", "_blank"),
                  },
                  {
                    text: "Discord",
                    icon: faCommentSmile,
                    handleClick: () =>
                      window.open("https://discord.gg/34SQh4cmMk", "_blank"),
                  },
                  {
                    text: "Support",
                    icon: faQuestionCircle,
                    handleClick: () =>
                      window.open("https://storiai.com/contact", "_blank"),
                  },
                ],
                [
                  {
                    text: "Logout",
                    icon: faArrowRightFromBracket,
                    handleClick: () => handleLogout(),
                    color: "text-red-500",
                  },
                ],
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
