import { useEffect } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useUpdatePost } from "../../../hooks/useUpdatePost";
import { SocialNetworkType } from "../../../../../utils/enums/Enums";
import { ISinglePostVM } from "../../../../../utils/interfaces/IPost";
import { usePutPost } from "../../../../../api/posts/put";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import _ from "lodash";

export const useReturnedTextVersions = (initialLoad: boolean, setIsUpdatedByCopilot: (value: boolean) => void) => {
  const { updateTextUI } = useUpdatePost();
  const { mutateAsync: putPost } = usePutPost();
  const { briefMessages, postData, setPostData } = useCreationStore();
  const {
    getSocialNetworkPostWithSocialNetworkType,
    getSocialNetworkTypeByName,
  } = useCreationHelperFunctions();

  useEffect(() => {
    const lastMessage = briefMessages[briefMessages.length - 1];
    initialLoad && setIsUpdatedByCopilot(true);
    if (initialLoad && lastMessage?.content && lastMessage.content.Type === "TextVersions") {
      // daabruna xutive soc kselistvis
      // davsete tmpPostDatashi kvela text versia kvela soc kselistvis da bolos gavushvi backshi. jer state davaaupdate
      if (lastMessage.content.Data.TextVersions!.length > 1) {
        let tmpPostData = _.cloneDeep(postData);
        lastMessage.content.Data.TextVersions!.map((item) => {
          tmpPostData?.socialNetworkPosts.map((socItem) => {
            if (
              getSocialNetworkTypeByName(item.SocialNetworkType) ===
              socItem.socialNetworkType
            ) {
              socItem.text = item.TextVersion;
            }
          });
        });
        setPostData(tmpPostData as ISinglePostVM);
        putPost({
          postData: tmpPostData as ISinglePostVM,
          postId: postData!.id,
        });
      }
      // daabruna erti soc kselistvis
      else {
        if (initialLoad && lastMessage.content.Data.TextVersions?.length === 1)
          updateTextUI({
            text: lastMessage.content.Data.TextVersions[0].TextVersion,
            socialNetworkPostId: getSocialNetworkPostWithSocialNetworkType(
              getSocialNetworkTypeByName(
                lastMessage.content.Data.TextVersions[0].SocialNetworkType
              ) as SocialNetworkType
            )!.id,
          });
        let tmpPostData = _.cloneDeep(postData);
        lastMessage.content.Data.TextVersions!.map((item) => {
          tmpPostData?.socialNetworkPosts.map((socItem) => {
            if (
              getSocialNetworkTypeByName(item.SocialNetworkType) ===
              socItem.socialNetworkType
            ) {
              socItem.text = item.TextVersion;
            }
          });
        });
        setPostData(tmpPostData as ISinglePostVM);
        putPost({
          postData: tmpPostData as ISinglePostVM,
          postId: postData!.id,
        });
      }
    }
  }, [briefMessages]);
};
