import React from "react";
import ImageSelectorSidebar from "./Frames/ImageSelectorSidebar";

function Frames() {
  return (
    <div style={{ height: "inherit" }} className="flex">
      <ImageSelectorSidebar />
    </div>
  );
}

export default Frames;
