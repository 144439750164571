import { useMutation } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { ISingleFolderVM } from "../../../utils/interfaces/IFolders";

export interface IGetFolderByIdProps {
  id?: number;
}

export const useGetFolderById = () => {
  return useMutation<IBaseData<ISingleFolderVM>, unknown, IGetFolderByIdProps>({
    mutationFn: async ({ id }: IGetFolderByIdProps) => {
      const response = await makeRequest.get(`/Folders/${id}`);
      return response as IBaseData<ISingleFolderVM>;
    },
  });
};
