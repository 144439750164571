import React, { useEffect, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/pro-regular-svg-icons";

interface VoiceRecorderProps {
  className?: string;
  canvasProps?: string;
  value: string;
  setValue: (value: string) => void;
  lastState: string;
  setLastState: (lastState: string) => void;
  listening: boolean;
  transcript: string;
  resetTranscript: () => void;
}

const VoiceRecorder = ({
  className,
  canvasProps,
  value,
  setValue,
  lastState,
  setLastState,
  listening,
  transcript,
  resetTranscript,
}: VoiceRecorderProps) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const animationRef = useRef<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript, setValue, listening]);

  useEffect(() => {
    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
      if (
        audioContextRef.current &&
        audioContextRef.current.state !== "closed"
      ) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const handleRecord = async () => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast(
        "Your browser does not support speech recognition. Please try a different browser.",
      );
      return;
    }

    if (listening) {
      if (lastState.length > 0) setValue(lastState + " " + value);
      setLastState("");
      await SpeechRecognition.stopListening();
      stopVisualizer();
      resetTranscript();
    } else {
      resetTranscript();
      setLastState(value);
      setValue("");
      await SpeechRecognition.startListening({ continuous: true });
      await startVisualizer();
    }
  };

  const startVisualizer = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      animateVisualizer();
    } catch (error) {
      toast("Error accessing microphone. Please check your permissions.");
    }
  };

  const stopVisualizer = () => {
    if (animationRef.current !== null) {
      cancelAnimationFrame(animationRef.current);
    }
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.close();
    }
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  const animateVisualizer = () => {
    if (!analyserRef.current || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) return;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationRef.current = requestAnimationFrame(draw);
      if (!analyserRef.current) return;

      analyserRef.current.getByteFrequencyData(dataArray);

      canvasCtx.fillStyle = "rgb(255, 255, 255)";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      const barWidth = 4;
      const barGap = 2;
      const barCount = Math.floor(canvas.width / (barWidth + barGap));
      const dataStep = Math.floor(bufferLength / barCount);
      const centerY = canvas.height / 2;

      for (let i = 0; i < barCount; i++) {
        const dataIndex = i * dataStep;
        const barHeight = (dataArray[dataIndex] / 255) * canvas.height;
        const x = i * (barWidth + barGap);
        const y = centerY - barHeight / 2;

        canvasCtx.fillStyle = "rgb(143,88,233)";
        canvasCtx.beginPath();
        canvasCtx.moveTo(x, y + barHeight / 2);
        canvasCtx.arcTo(x, y, x + barWidth, y, barWidth / 2);
        canvasCtx.arcTo(
          x + barWidth,
          y,
          x + barWidth,
          y + barHeight,
          barWidth / 2,
        );
        canvasCtx.arcTo(
          x + barWidth,
          y + barHeight,
          x,
          y + barHeight,
          barWidth / 2,
        );
        canvasCtx.arcTo(x, y + barHeight, x, y, barWidth / 2);
        canvasCtx.fill();
      }
    };

    draw();
  };

  const handleStop = async () => {
    setValue(lastState);
    await SpeechRecognition.stopListening();
    stopVisualizer();
    resetTranscript();
  };

  return (
    <div className="relative flex flex-col items-center">
      {listening && (
        <div
          className={`absolute bottom-14 w-[100px] h-[40px] mb-2 ${canvasProps}`}
        >
          <canvas
            ref={canvasRef}
            width="100"
            height="50"
            className="rounded-md border border-gray-300"
          />
          <div
            className="absolute -top-2 -right-2 w-6 h-6 flex justify-center items-center bg-gray-200 rounded-full cursor-pointer"
            onClick={handleStop}
          >
            <FontAwesomeIcon icon={faX} className="text-gray-700 text-xs" />
          </div>
        </div>
      )}
      <div
        onClick={handleRecord}
        className={`${className}
          ${listening ? "bg-green-200 text-green-600" : "text-gray-500"}
          w-10 h-10 rounded-full flex justify-center items-center cursor-pointer transition-colors duration-300
        `}
      >
        <FontAwesomeIcon icon={listening ? faCheck : faMicrophone} />
      </div>
    </div>
  );
};

export default VoiceRecorder;
