import React from "react";
import { useGetImageIdeas } from "../../../../../../api/creation/imageIdeas/get";
import { useHelperInputHelperFunctions } from "../useHelperInputHelperFunctions";

function SuggestedPrompts({
  setInputValue,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { imageIdeas } = useHelperInputHelperFunctions();
  return (
    <>
      {imageIdeas && imageIdeas.data.length > 0 ? (
        <>
          <div className="px-[24px] mt-[22px] mb-[12px]">
            <p className="text-xs-m text-gray-400">Suggested Prompts:</p>
            <div className="mt-[8px]">
              {imageIdeas.data.slice(0, 3).map((item) => (
                <p
                  onClick={() => setInputValue(item.idea)}
                  className="cursor-pointer text-sm text-gray-600 px-[12px] py-[6px] hover:bg-gray-100 rounded-[8px]"
                  key={item.id}
                >
                  <span className={"text-[12px] pr-[5px] text-gray-300"}>
                    ○
                  </span>{" "}
                  <i>{item.idea}</i>
                </p>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SuggestedPrompts;
