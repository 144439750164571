import { NotificationType } from "../../../utils/enums/Enums";

export const notificationValueByType = (
  type: NotificationType,
  arr: string[]
): string => {
  switch (type) {
    case NotificationType.COMMENT:
      return arr[0];
    case NotificationType.INVITATION:
      return arr[1];
    case NotificationType.ExamplePostCreated:
      return arr[2];
    case NotificationType.ReminderPostReadyForPublishing:
      return arr[3];
    case NotificationType.PostDraftWasCreated:
      return arr[4];
    case NotificationType.PostWasPublished:
      return arr[5];
    case NotificationType.PostWasScheduled:
      return arr[6];
    case NotificationType.ScheduledPostWasPublished:
      return arr[7];
    case NotificationType.UserJoinedTheBrand:
      return arr[8];
    default:
      return "";
  }
};
