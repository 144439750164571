import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useGetCopilotChat = ({
  sessionId,
}: {
  sessionId?: null | string;
}) => {
  return useQuery<IBaseData<ICopilotMessage[]>>({
    queryKey: ["useGetCopilotChat"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Copilot/${sessionId}`);
      return response as IBaseData<ICopilotMessage[]>;
    },
    retry: 0,
    enabled: false,
  });
};
