import { TeamPackageStatus } from "../../utils/enums/Enums";
import { BillingInfoIcons } from "./BillingInfoIcons";

export const BillingInfoTexts = ({
  type,
  isTrial,
}: {
  type: TeamPackageStatus;
  isTrial: boolean;
}) => {
  return (
    <div className="flex flex-col items-start">
      <div className="text-lg-sb flex items-center gap-2">
        <BillingInfoIcons type={type} isTrial={isTrial} />
        <h1>
          {type === TeamPackageStatus.CANCELED && `Reactivate Your STORI!`}
          {type === TeamPackageStatus.PAUSED && `Subscription Paused!`}
          {type === TeamPackageStatus.ACTIVE && !isTrial && `Your STORI is Active`}
          {type === TeamPackageStatus.ACTIVE && isTrial && `STORI Trial is Active`}
        </h1>
      </div>
      <p className="text-gray-700 text-sm">
        {type === TeamPackageStatus.CANCELED &&
          `Your plan is on hold. Press “Activate now” to add a payment method and access all STORI features.`}
        {type === TeamPackageStatus.PAUSED &&
          `Your subscription is paused due to trial or payment expiration. Press “Manage plan” Reactivate your subscription and keep using STORI.`}
        {type === TeamPackageStatus.ACTIVE &&
          !isTrial &&
          `Subscription is active and good to go! You can upgrade your plan to unlock even more opportunities.`}
        {type === TeamPackageStatus.ACTIVE &&
          isTrial &&
          `You can enjoy all STORI features for now. Add a payment method to unlock the full potential of STORI.`}
      </p>
    </div>
  );
};
