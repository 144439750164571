import React from "react";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

function UserMessage({ msg }: { msg: ICopilotMessage }) {
  return (
    <div className="flex justify-end">
      <div className="flex flex-col gap-[6px]">
        <p className="text-sm-m text-gray-700 text-right">You</p>
        <pre className="whitespace-pre-wrap break-words max-w-[390px] w-full text-md text-base-white rounded-[8px] rounded-tr-none py-[10px] px-[14px] bg-brand-600">
          {msg.content?.Message}
        </pre>
      </div>
    </div>
  );
}

export default UserMessage;
