import { useState } from "react";
import SBreadcrumbs from "../../../../../design-system/SBreadcrumbs";
import { useLibraryStore } from "../../../../../store/libraryStore";

function Head() {
  const { folderHistory, setFolderHistory, setFoldersAndFiles, setPage, setParentFolder } = useLibraryStore();
  const [selectedFileType, setSelectedFileType] = useState(0);
  return (
    <div className="flex justify-between items-center">
      <SBreadcrumbs
        needGoBack={folderHistory.length >= 2}
        onGoBack={() => {
          const prevItem = folderHistory[folderHistory.length - 2];
          folderHistory.length > 2 &&
            setFolderHistory((prev) => prev.slice(0, -1));
          setFoldersAndFiles([]);
          setPage(1);
          setParentFolder({
            id: prevItem?.id ? prevItem?.id : null,
            title: prevItem.title,
          });
        }}
        needHome={false}
        pages={folderHistory}
      />
      {/* <SUnderlineTabs
        items={[
          {
            index: 0,
            text: "Images",
          },
          {
            index: 1,
            text: "Videos",
          },
        ]}
        selected={selectedFileType}
        setSelected={(e) => setSelectedFileType(e)}
      /> */}
    </div>
  );
}

export default Head;
