import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";

export const getSelectedAssets = (
  selected: { id: number; isFolder: boolean }[],
  assets: ISingleFoldersAndFilesVM[]
) => {
  const fileIds = selected
    .filter((item) => !item.isFolder)
    .map((item) => item.id);

  const folderIds = selected
    .filter((item) => item.isFolder)
    .map((item) => item.id);

  const files = assets.filter((asset) => fileIds.includes(asset.id));
  const folders = assets.filter((asset) => folderIds.includes(asset.id));

  return { files, folders };
};
