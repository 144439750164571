import { IPlanData } from "../../../pages/UpdatePlan";
import { BillingPeriod } from "../../../utils/enums/Enums";
import {
  ISubscriptionVM,
} from "../../../utils/interfaces/ISubscriptions";

export const manageOrUpdate = (
  changePlan: ({ packageId }: { packageId: number }) => void,
  billingType: BillingPeriod,
  manageSubscription: { data: string } | undefined,
  currentPackage?: ISubscriptionVM,
  selectedPlan?: IPlanData,
) => {
  const upgradePlan = () => {
    if (selectedPlan && selectedPlan.details?.id !== undefined) {
      changePlan({ packageId: selectedPlan.details.id });
    }
  };
  const managePlan = () => {
    if (!manageSubscription) return;
    window.open(manageSubscription.data, "_self");
  };
  if (selectedPlan?.name !== currentPackage?.package.name.split(" ")[0]) {
    upgradePlan();
  } else {
    if (currentPackage?.package.billingPeriod !== billingType) {
      upgradePlan();
    } else {
      managePlan();
    }
  }
};
