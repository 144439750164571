import { TeamPackageStatus } from "../../../utils/enums/Enums";

export const determineColor = (type: TeamPackageStatus, isTrial: boolean) => {
  let bgColor = "";
  switch (type) {
    case TeamPackageStatus.CANCELED:
      bgColor = "bg-error-25";
      break;
    case TeamPackageStatus.PAUSED:
      bgColor = "bg-transparent";
      break;
    case TeamPackageStatus.ACTIVE:
      if (isTrial) {
        bgColor = "bg-warning-25";
      } else {
        bgColor = "bg-success-25";
      }
      break;
  }
  return bgColor;
}