import SModal from "../../design-system/SModal";
import { BrandingSectionName } from "../../utils/interfaces/IBrand";

export interface BrandConfirmRegenerateSectionsProps {
  openModal: BrandingSectionName | false;
  setOpenModal: (value: BrandingSectionName | false) => void;
  onConfirm?: () => void;
}

export const BrandConfirmRegenerateSections = ({
  openModal,
  setOpenModal,
  onConfirm,
}: BrandConfirmRegenerateSectionsProps) => {
  return (
    <SModal
      isOpen={openModal !== false}
      setIsOpen={(value:any = openModal) => setOpenModal(value)}
      handleCancel={() => setOpenModal(false)}
      handleSubmit={onConfirm || (() => {})}
      title={"Are you sure you want to regenerate this part of the brand?"}
      type="warning"
      actionButtonText="Confirm"
      actionButtonProps={{
        onClick: onConfirm,
      }}
    />
  );
};
