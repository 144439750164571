import { useEffect, useState } from "react";
import { ISinglePostVM, ISocialNetworkPostVM } from "../../../utils/interfaces/IPost";

export const useTextChangeAnimation = ({
  getActiveSocialNetworkPost,
  postData,
  isPostPending,
  isUpdatedByCopilot,
  setIsUpdatedByCopilot,
}: {
  getActiveSocialNetworkPost: () => ISocialNetworkPostVM | undefined;
  postData?: ISinglePostVM;
  isPostPending: boolean;
  isUpdatedByCopilot: boolean;
  setIsUpdatedByCopilot: (value: boolean) => void;
}) => {
  const [insertAnimation, setInsertAnimation] = useState(false);
  const [oldText, setOldText] = useState(getActiveSocialNetworkPost()?.text);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (isUpdatedByCopilot && initialLoad && oldText !== getActiveSocialNetworkPost()?.text) {
      setOldText(getActiveSocialNetworkPost()?.text);
      setInsertAnimation(true);
      setIsUpdatedByCopilot(false);
    }
    return () => {
      setTimeout(() => {
        setInsertAnimation(false);
      }, 1000);
    };
  }, [getActiveSocialNetworkPost()]);

  useEffect(() => {
    if (postData && !isPostPending) {
      setInitialLoad(true);
    }
  }, [postData, getActiveSocialNetworkPost()?.text, setInitialLoad, isUpdatedByCopilot]);

  return { insertAnimation };
}
