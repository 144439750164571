import React, { useEffect, useState } from "react";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
//@ts-ignore
import logo from "../assets/images/logos/stori-s-square.svg";
//@ts-ignore
import bgGrid from "../assets/images/grid-background.png";
import SButton from "../design-system/SButton";
import SInput from "../design-system/SInput";
import { useSignIn } from "../api/auth/signIn/post";
import { getQuery } from "../hooks/functions/queries";
import { useGetInviteInfoByCode } from "../api/invitations/invitationByCode/get";
import { useLocation, useNavigate } from "react-router-dom";
import { useCanvaSignIn } from "../api/auth/canva/signIn/post";
import { toast } from "../hooks/toast";

const CanvaSignin = () => {
  const { mutateAsync: signIn, isPending } = useCanvaSignIn();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  const invitationCode = getQuery("code");
  const errorMessage = getQuery("message");
  const { data: invitationByCode } = useGetInviteInfoByCode({
    code: invitationCode,
  });

  const handleSubmit = () => {
    const canvaToken = getQuery("canva_user_token");
    if (canvaToken) {
      // @ts-ignore
      signIn({ email, password, canvaToken: canvaToken }).catch((err) => {
        if (err.response.status === 401) {
          window.location.href = err.response.data.message;
        } else {
          setError(err.response.data.message ?? "Invalid login attempt.");
        }
      });
    }
  };

  useEffect(() => {
    if (invitationByCode) {
      setEmail(invitationByCode?.data.userEmail);
      setShowPasswordInput(true);
    }
  }, [invitationByCode]);
  const location = useLocation();
  const navigate = useNavigate();
  const handleGoogleLogin = () => {
    // eslint-disable-next-line no-undef
    // @ts-ignore
    const searchParams = new URLSearchParams(location.search);
    const canvaToken = getQuery("canva_user_token");
    const state = getQuery("state");
    window.location.href = `${
      process.env.REACT_APP_API_ENDPOINT
    }auth/external-login?provider=Google&isFromCanva=${true}&canva_user_token=${canvaToken}&state=${state}`;
  };

  useEffect(() => {
    if (errorMessage) {
      toast(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <div className="flex h-[100dvh] justify-center items-center max-md:p-[16px]">
        <div
          className={`flex flex-col items-center justify-center max-w-[380px] w-full`}
        >
          <img
            className="absolute z-[-1] top-[-10%] w-[50%] h-[300px] object-cover"
            src={bgGrid}
            alt="bg"
          />
          <img alt={"logo"} src={logo} className="w-[48px] mb-[20px]" />
          <p className="display-sm-sb text-gray-900 text-center">
            Log in to your account
          </p>
          <p className="text-md text-gray-600 mt-[6px] text-center">
            Welcome back! Please enter your details.
          </p>
          <div className="flex flex-col mt-[32px] gap-[10px] w-full">
            <SInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              label="Email*"
              onKeyDown={(e) => {
                if (e.code === "Enter") handleSubmit();
              }}
            />
            <SInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Enter your password"
              label="Password*"
              onKeyDown={(e) => {
                if (e.code === "Enter") handleSubmit();
              }}
            />
            <div className="flex justify-end">
              <SButton
                type="linkColor"
                size="md"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot password?
              </SButton>
            </div>
            {error && <p className="text-error-500">{error}</p>}
          </div>
          <div className="mt-[18px] flex flex-col gap-[16px] w-full">
            <SButton isLoading={isPending} size="lg" onClick={handleSubmit}>
              Sign in
            </SButton>
            <SButton
              onClick={handleGoogleLogin}
              size="lg"
              type="secondaryGray"
              lIconProps={{
                color: "#EA4335",
              }}
              lIcon={faGoogle}
            >
              Sign in with Google
            </SButton>
          </div>
          <p className="text-sm mt-[32px] text-center text-gray-600">
            Don’t have an account?
            <span
              className="text-sm-sb text-brand-700 cursor-pointer"
              onClick={() => navigate(`/signup${location.search}`)}
            >
              {" "}
              Sign up
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default CanvaSignin;
