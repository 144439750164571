import { useEffect, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useGetCopilotChat } from "../../../../../api/copilot/chat/get";
import { useGetCopilotSession } from "../../../../../api/copilot/session/get";
import { CopilotRole, CopilotType } from "../../../../../utils/enums/Enums";
import { useBriefHelperFunctions } from "./useBriefHelperFunctions";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";
import { usePostCopilotSession } from "../../../../../api/copilot/session/post";

export const useFillChat = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    postData,
    setCopilotSessionId,
    postIdea,
    setPostIdea,
    copilotSessionId,
  } = useCreationStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: copilotSessionId,
  });
  const { sendMessage } = useBriefHelperFunctions({
    files: null,
    setDisable,
    setChatResponse,
    setValue,
  });
  const { setBriefMessages, briefMessages } = useCreationStore();
  const { mutateAsync: createCopilotSession } = usePostCopilotSession();
  const [initialLoad, setInitialLoad] = useState(false);
  const { refetch: refetchCopilotSession } = useGetCopilotSession({
    enabled: false,
    type: CopilotType.POST,
    postId: postData?.id,
  });
  useEffect(() => {
    if (!copilotSessionId && postData?.id) {
      refetchCopilotSession().then((res) => {
        //@ts-ignore
        if (res.isError) {
          createCopilotSession({
            type: CopilotType.POST,
            postId: postData.id,
          }).then((res) => {
            setCopilotSessionId(res.data.id);
          });
        } else {
          //@ts-ignore
          setCopilotSessionId(res.data.data[0].id);
        }
      });
    }
  }, [postData]);

  useEffect(() => {
    if (postIdea && briefMessages.length > 0) {
      let isThereUserMessage = briefMessages.find(
        (item) => item.role === CopilotRole.USER
      );
      if (!isThereUserMessage) {
        sendMessage(postIdea);
        setPostIdea(null);
      }
    }
  }, [postIdea, briefMessages]);

  useEffect(() => {
    if (copilotSessionId) {
      refetchCopilotMessages().then((res) => {
        if (res.isError) {
          if (postData?.id)
            createCopilotSession({
              type: CopilotType.POST,
              postId: postData.id,
            }).then((res) => {
              setCopilotSessionId(res.data.id);
            });
        } else {
          let tmp: ICopilotMessage[] = [];
          res!.data!.data.map((item) => {
            tmp.push({
              files: item.files,
              content: item.content,
              role: item.role,
            });
          });
          setBriefMessages(tmp);
          setInitialLoad(true);
        }
      });
    }
  }, [copilotSessionId]);

  return { initialLoad };
};
