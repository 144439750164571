import React from "react";
import SToggle from "../../design-system/SToggle";
import SButton from "../../design-system/SButton";

function IntegrationCard({
  name,
  description,
  logo,
  isToggled,
}: {
  name: string;
  description: string;
  logo: string;
  isToggled: boolean;
}) {
  return (
    <div className="w-full shadow-xs bg-base-white border border-gray-200 rounded-[12px] overflow-hidden">
      <div
        className={`h-[calc(100%-52px)] p-[24px] flex flex-col gap-[24px] border-b border-b-gray-200 ${!isToggled && "opacity-[0.5]"}`}
      >
        <div className="flex justify-between">
          <div className="flex gap-[16px] items-center">
            <img src={logo} alt={`${name}-logo`} />
            <p className="text-md-sb text-gray-900">{name}</p>
          </div>
          {/*<SToggle setIsToggled={() => null} isToggled={isToggled} size="sm" />*/}
        </div>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      {!isToggled ? (
        <div className="min-h-[52px] max-h-[52px] flex items-center justify-end px-[12px]">
          <p className="text-sm-sb text-brand-700 cursor-default">
            Coming soon
          </p>
        </div>
      ) : (
        <div className="min-h-[52px] max-h-[52px] flex items-center justify-end px-[12px]">
          <p className="text-sm-sb text-green-700 cursor-default">Active</p>
        </div>
      )}
    </div>
  );
}

export default IntegrationCard;
