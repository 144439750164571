import {
  faBars,
  faBook,
  faCalendarDay,
  faCreditCard,
  faDollarCircle,
  faGear,
  faGrid2,
  faHome,
  faImage,
  faLink,
  faPaintBrush,
  faPlusCircle,
  faShare,
  faShareNodes,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import NavigationGroup from "./NavigationGroup";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons/faMegaphone";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";

function Navigation({
  setIsMobileMenuOpen,
}: {
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const accountStore = useAccountStore();
  return (
    <div className="mx-[12px] mt-[32px]">
      <div className="flex flex-col gap-[16px]">
        <NavigationGroup
          pages={[
            {
              title: "Dashboard",
              icon: faGrid2,
              path: "/",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
            {
              title: "Brand",
              icon: faPaintBrush,
              path: "/brand?section=brandbook",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
            {
              title: "Content",
              icon: faMegaphone,
              path: "/content",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },

            {
              title: "Library",
              icon: faImage,
              path: "/assets",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
          ]}
        />
        <NavigationGroup
          borderBottom={false}
          pages={[
            {
              title: "Calendar",
              icon: faCalendarDay,
              path: "/calendar",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
            {
              title: "Accounts",
              icon: faShareNodes,
              path: "/brand?section=accounts",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
            {
              title: "Integrations",
              icon: faLink,
              path: "/integrations",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
          ]}
          title="Manage"
        />
        <NavigationGroup
          borderBottom={false}
          pages={[
            {
              title: "Settings",
              icon: faGear,
              path: "/workspace?section=overview",
              handleClick: () =>
                setIsMobileMenuOpen && setIsMobileMenuOpen(false),
            },
            ...(accountStore.personalInfo?.userRole ===
            OrganizationUserRole.VIEWER
              ? []
              : [
                  {
                    title: "Billing",
                    icon: faCreditCard,
                    path: "/workspace?section=billing",
                    handleClick: () =>
                      setIsMobileMenuOpen && setIsMobileMenuOpen(false),
                  },
                ]),
          ]}
          title="Workspace"
        />
        {/* <NavigationGroup
          borderBottom={false}
          pages={[
            {
              title: "Settings",
              icon: faGear,
              path: "/workspace",
            },
            {
              title: "Billing",
              icon: faDollarCircle,
              path: "/billing",
            },
            {
              title: "Create New Workspace",
              icon: faPlusCircle,
              handleClick: () => alert("123"),
              alwaysActive: true,
            },
          ]}
          title="Workspace"
        /> */}
      </div>
    </div>
  );
}

export default Navigation;
