import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ICopilotSessionVM } from "../utils/interfaces/ICopilot";
import { ICopilotMessage } from "../utils/copilot-base/ICopilotBase";

export interface ICreationStoreInitialInterface {
  isCopilotOpen: boolean;
  activeSession: ICopilotSessionVM | null;
  messages: ICopilotMessage[];
  isInputDisabled: boolean;
  isWriting: boolean;
}

const initialState: ICreationStoreInitialInterface = {
  isCopilotOpen: false,
  activeSession: null,
  messages: [],
  isInputDisabled: false,
  isWriting: false,
};

const store = (set: any) => ({
  ...initialState,
  setIsCopilotOpen: (isCopilotOpen: boolean) => set({ isCopilotOpen }),
  setIsWriting: (isWriting: boolean) => set({ isWriting }),
  setIsInputDisabled: (isInputDisabled: boolean) => set({ isInputDisabled }),
  setMessages: (messages: ICopilotMessage[]) => set({ messages }),
  setActiveSession: (activeSession: ICopilotSessionVM | null) =>
    set({ activeSession }),
  reset: () => set(initialState),
});
export const useCopilotStore = create(devtools(store));
