import { ILibraryProps } from "../../pages/Library";
//@ts-ignore
import WordFile from "../../assets/icons/word-file.svg";
//@ts-ignore
import PDFIcon from "../../assets/icons/pdf.svg";
//@ts-ignore
import TextIcon from "../../assets/icons/text.svg";
import { useGridCardResize } from "./hooks/useGridCardResize";
import { useRef } from "react";
import { LibraryOptionsButton } from "./LibraryOptionsButton";

export function LibraryGridCard({
  className,
  cardType,
  cardTitle,
  imageSrc,
  isSelected = false,
  onClick,
  onDoubleClick,
  hasOptions = true,
  id,
  extension,
  pathInStorage,
  fileSize,
  handleMoveInto,
  parentFolderId,
  isRoot,
  setOpenConfirmation,
  handleRename,
  handleDelete,
  handleMoveOut,
  handleDownload,
  handleFolderDownload,
  mediumImageSrc,
}: ILibraryProps) {
  const { sizeRef, size } = useGridCardResize();
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={sizeRef}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className={`${className} select-none overflow-visible h-[275px] max-md:h-[fit-content] [&_button]:hover:inline cursor-pointer aspect-square flex flex-col w-[50%] md2:w-full rounded-[8px] border max-md:w-full ${isSelected ? "border-brand-200 bg-brand-200 hover:bg-brand-200" : "bg-gray-25 border-gray-200 hover:bg-gray-100"}  overflow-hidden`}
    >
      <div
        className={`w-full select-none h-full flex justify-center items-center pt-[26px]`}
      >
        {cardType === "Image" && mediumImageSrc && (
          <img
            src={mediumImageSrc}
            alt={cardTitle}
            className={`rounded-[8px] ${size && size <= 200 ? "w-[50%]" : "w-[85%] object-cover md:h-[150px] lg2:max-w-3/4"}`}
          />
        )}
        {cardType === "Video" && (
          <video
            muted
            className={`${size && size <= 200 ? "w-[50%]" : "w-[70%] md:max-h-[120px] lg2:max-w-3/4 rounded-[8px]"}`}
          >
            <source src={imageSrc} type="video/mp4" />
          </video>
        )}
        {cardType === "PDF" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={PDFIcon}
            alt="PDF FIle"
          />
        )}
        {cardType === "Word" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={WordFile}
            alt="word document"
          />
        )}
        {cardType === "Text" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={TextIcon}
            alt="text file"
          />
        )}
      </div>
      <div
        className={`flex relative h-[136px] flex-row justify-between items-center ${size && size <= 200 ? "py-0" : "py-[18px]"} px-[18px]`}
      >
        <div
          className={`flex flex-col items-start gap-[4px] ${size && size <= 200 ? "px-0" : "px-[20px]"} `}
        >
          <p
            className={`font-semibold break-all ${size && size <= 200 ? `${size && size <= 105 ? "text-xs-sb" : "text-sm-sb"}` : "text-md-sb"} `}
          >
            {size && size > 320 && cardTitle}
            {size && size <= 320 && cardTitle.substring(0, 15)}
            {extension}
          </p>
          <div
            className={`${size && size <= 160 ? "hidden" : "flex"} items-center gap-2 text-xs-m text-gray-500`}
          >
            <p>{fileSize && (fileSize / (1024 * 1024)).toFixed(2)} MB</p>
          </div>
        </div>
        {handleDownload &&
          handleFolderDownload &&
          handleMoveOut &&
          handleMoveInto && (
            <LibraryOptionsButton
              id={id}
              isRoot={isRoot}
              isContent={false}
              cardType={cardType}
              cardTitle={cardTitle}
              imageSrc={imageSrc}
              hasOptions={hasOptions}
              dropdownRef={dropdownRef}
              handleDelete={(e) => {
                setOpenConfirmation && setOpenConfirmation(true);
                handleDelete && handleDelete({ e, id, cardType: "File" });
              }}
              handleRename={(e) =>
                handleRename &&
                handleRename({
                  e,
                  id,
                  cardType: "File",
                  path: pathInStorage,
                  extension,
                })
              }
              handleDownload={(e) => {
                imageSrc &&
                  handleDownload({
                    e,
                    url: imageSrc,
                    name: cardTitle + extension,
                  });
              }}
              handleFolderDownload={handleFolderDownload}
              handleMoveOut={(e) =>
                handleMoveOut({
                  e,
                  fileIds: cardType !== "Folder" ? [id] : [],
                  folderIds: cardType === "Folder" ? [id] : [],
                  parentId: parentFolderId,
                })
              }
              handleMoveInto={(e) =>
                handleMoveInto({
                  e,
                  fileIds: cardType !== "Folder" ? [id] : [],
                  folderIds: cardType === "Folder" ? [id] : [],
                })
              }
            />
          )}
      </div>
    </div>
  );
}
