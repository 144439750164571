import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";

export const ViewerRestrictTooltip = ({
  title = "Viewers are not allowed to create new posts.",
}: {
  title?: string;
}) => {
  const { personalInfo } = useAccountStore();
  const randomId = "create-new-post-tooltip" + Math.random();
  return (
    <>
      {personalInfo?.userRole === OrganizationUserRole.VIEWER && (
        <>
          <FontAwesomeIcon
            className="text-gray-500"
            size="sm"
            data-tooltip-id={randomId}
            icon={faCircleQuestion}
          />
          <Tooltip
            id={randomId}
            place="top"
            style={{ zIndex: 100 }}
            disableStyleInjection
            className="text-sm"
            children={title}
          />
        </>
      )}
    </>
  );
};
