import { useLibraryStore } from "../../../store/libraryStore";

export const useGoBack = () => {
  const {
    folderHistory,
    setFolderHistory,
    setFoldersAndFiles,
    setPage,
    setParentFolder,
  } = useLibraryStore();
  const onGoBack = () => {
    const prevItem = folderHistory[folderHistory.length - 2];
    folderHistory.length > 2 && setFolderHistory((prev) => prev.slice(0, -1));
    setFoldersAndFiles([]);
    setPage(1);
    setParentFolder({
      id: prevItem?.id ? prevItem?.id : null,
      title: prevItem.title,
    });
  };

  return { onGoBack };
};
