import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Dispatch, SetStateAction } from "react";
import { IBreadcrumbsItem } from "../design-system/SBreadcrumbs";
import { ISingleFoldersAndFilesVM } from "../utils/interfaces/IAssets";

export interface ILibraryStoreInitialInterface {
  folderHistory: IBreadcrumbsItem[];
  setFolderHistory: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  page: number;
  parentFolder: {
    id: number | null;
    title: string;
  } | null;
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setParentFolder: Dispatch<
    SetStateAction<{
      id: number | null;
      title: string;
    } | null>
  >;
}

const initialState: ILibraryStoreInitialInterface = {
  folderHistory: [],
  foldersAndFiles: [],
  page: 1,
  parentFolder: null,
  setFolderHistory: () => {},
  setFoldersAndFiles: () => {},
  setPage: () => {},
  setParentFolder: () => {},
};

const store = (set: any) => ({
  ...initialState,
  setFolderHistory: (folderHistory: IBreadcrumbsItem[] | ((prevFolderHistory: IBreadcrumbsItem[]) => IBreadcrumbsItem[])) => set((state: ILibraryStoreInitialInterface) => ({
    folderHistory: typeof folderHistory === 'function' ? folderHistory(state.folderHistory) : folderHistory
  })),
  setFoldersAndFiles: (foldersAndFiles: ISingleFoldersAndFilesVM[] | ((prevFoldersAndFiles: ISingleFoldersAndFilesVM[]) => ISingleFoldersAndFilesVM[])) => set((state: ILibraryStoreInitialInterface) => ({
    foldersAndFiles: typeof foldersAndFiles === 'function' ? foldersAndFiles(state.foldersAndFiles) : foldersAndFiles
  })),
  setPage: (page: number | ((prevPage: number) => number)) => set((state: ILibraryStoreInitialInterface) => ({
    page: typeof page === 'function' ? page(state.page) : page
  })),
  setParentFolder: (parentFolder: { id: number | null; title: string } | null | ((prevParentFolder: { id: number | null; title: string } | null) => { id: number | null; title: string } | null)) => set((state: ILibraryStoreInitialInterface) => ({
    parentFolder: typeof parentFolder === 'function' ? parentFolder(state.parentFolder) : parentFolder
  })),
  reset: () => set(initialState),
});
export const useLibraryStore = create(devtools(store));
