import {
  faComments,
  faMessages,
  faSend,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SInput from "../../design-system/SInput";
import SButton from "../../design-system/SButton";
import Head from "./comments/Head";
import Message from "./comments/Message";
import { usePostComment } from "../../api/creation/comment/post";
import { useCreationStore } from "../../store/creationStore";
import { useUpdatePost } from "./hooks/useUpdatePost";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";

function CommentsModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, setValue] = useState("");
  const { postData } = useCreationStore();
  const { updateComments } = useUpdatePost();
  const wrapperRef = useRef<any>();

  useEffect(() => {
    document.getElementById("post__comments__chat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "auto",
    });
  }, [isOpen]);

  useHandleOutsideClick({
    callBack: () => onClose(),
    ref: wrapperRef,
    excludedIds: ["post__comments__chat-opener"],
  });

  return (
    <div
      ref={wrapperRef}
      className={`
      max-w-[440px] w-[440px] z-20 absolute top-[50px] right-[90px] bg-transparent rounded-[12px] overflow-hidden shadow-xl border border-gray-200
    `}
    >
      <Head onClose={onClose} />
      <div
        id="post__comments__chat"
        className="overflow-auto flex flex-col gap-[12px] max-h-[240px] px-[16px] py-[16px] bg-base-white w-full"
      >
        {postData?.comments.map((item) => <Message comment={item} />)}
      </div>
      <div className="p-[16px] border-t border-gray-200 flex gap-[12px] bg-base-white">
        <SInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Message..."
          className="w-full"
          onKeyDown={(e) => {
            if (e.code === "Enter" && value.length > 0) {
              updateComments({ text: value }).finally(() => {
                setValue("");
                document.getElementById("post__comments__chat")?.scrollTo({
                  top: 1000000,
                  left: 0,
                  behavior: "smooth",
                });
              });
            }
          }}
        />
        <SButton
          onClick={() => {
            if (value.length > 0) {
              updateComments({ text: value }).finally(() => {
                setValue("");
                document.getElementById("post__comments__chat")?.scrollTo({
                  top: 1000000,
                  left: 0,
                  behavior: "smooth",
                });
              });
            }
          }}
          icon={faSend}
          className="h-[42px]"
          type="secondaryColor"
        />
      </div>
    </div>
  );
}

export default CommentsModal;
