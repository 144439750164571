import React, { ReactNode, useRef } from "react";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import NotificationsInHeaderItem from "./NotificationsInHeaderItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetNotifications } from "../../api/notifications/get";
import NotificationSkeleton from "../notifications/NotificationSkeleton";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import { NotificationType } from "../../utils/enums/Enums";
import { useRespondFromNotification } from "../notifications/hooks/useRespondFromNotification";

const NotificationsTabInHeader = ({
  isNotificationsOpen,
  setIsNotificationsOpen,
  getValue,
}: {
  isNotificationsOpen: boolean;
  setIsNotificationsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getValue: (
    item: ISingleNotification,
    type: NotificationType,
    organizationOrWorkspace: string,
  ) => ReactNode | string;
}) => {
  const notificationsRef = useRef<any>();
  const navigate = useNavigate();

  useHandleOutsideClick({
    ref: notificationsRef,
    callBack: () => {
      setIsNotificationsOpen(false);
    },
    excludedIds: ["header__notifications-opener"],
  });

  const { data: notifications, isFetching } = useGetNotifications({
    page: 1,
    pageSize: 10,
  });
  const { handleClick } = useRespondFromNotification();
  return (
    <div
      ref={notificationsRef}
      className={
        "absolute top-[55px] right-[90px] max-md:right-[65px] w-[424px] max-md:w-[70%] flex flex-col bg-base-white shadow-2xl border-[1px] border-gray-200 rounded-[16px] p-[24px] pb-[0px] overflow-hidden"
      }
    >
      <div
        className={
          "w-full text-lg-sb text-gray-900 pb-[20px] border-b border-gray-200"
        }
      >
        Notifications
      </div>
      <div className={"max-h-[300px] overflow-y-auto"}>
        {notifications?.data.notifications.map((notification, index) => {
          const organizationName =
            notification.customProperty.organizations &&
            notification.customProperty.organizations[0]?.name;
          const organizationOrWorkspace = organizationName
            ? organizationName + " brand "
            : notification.customProperty.workspace?.name;
          return (
            <NotificationsInHeaderItem
              message={getValue(
                notification,
                notification.type,
                organizationOrWorkspace,
              )}
              handleCancel={(e) => {
                e.stopPropagation();
                notification.type === NotificationType.INVITATION &&
                  handleClick(
                    notification.id,
                    notification.customProperty.id,
                    3,
                    !!organizationName,
                  );
              }}
              handleSubmit={(e) => {
                e.stopPropagation();
                notification.type === NotificationType.INVITATION &&
                  handleClick(
                    notification.id,
                    notification.customProperty.id,
                    2,
                    !!organizationName,
                  );
              }}
              notification={notification}
              key={index}
              setIsNotificationsOpen={setIsNotificationsOpen}
            />
          );
        })}
        {isFetching && (
          <div className="w-full flex flex-col gap-4 py-4">
            <NotificationSkeleton />
            <NotificationSkeleton />
            <NotificationSkeleton />
          </div>
        )}
        {
          !isFetching && notifications?.data.notifications.length === 0 && (
            <div className="w-full text-center text-sm text-gray-500 py-[12px]">
              You've no unread notifications
            </div>
          )
        }
      </div>
      <div
        className={
          "w-full text-center text-sm text-brand-600 py-[12px] cursor-pointer"
        }
        onClick={() => {
          setIsNotificationsOpen(false);
          navigate("/notifications");
        }}
      >
        View all <FontAwesomeIcon icon={faChevronRight} fontSize={"12px"} />
      </div>
    </div>
  );
};

export default NotificationsTabInHeader;
