import { Dispatch, SetStateAction } from "react";
import { handleFolderChange } from "./handleFolderChange";
import { IBreadcrumbsItem } from "../../../design-system/SBreadcrumbs";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";
import { ILibraryProps } from "../../../pages/Library";
import { IExpandedImage } from "../LibraryView";

export interface ISelectDoubleClick
  extends Omit<ILibraryProps, "createdAt" | "author"> {
  clickTimeout?: number | null;
  extension?: string;
  pathInStorage?: string;
  setClickTimeout?: Dispatch<SetStateAction<number | null>>;
  setSelectedItems?: ([{ id, isFolder }]: {
    id: number;
    isFolder: boolean;
  }[]) => void;
  setParentFolder?: Dispatch<
    SetStateAction<null | { id: number | null; title: string }>
  >;
  setFolderHistory?: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setExpandedImage?: Dispatch<SetStateAction<IExpandedImage | null>>;
}

export const handleDoubleClick = ({
  extension,
  pathInStorage,
  clickTimeout,
  setClickTimeout,
  setSelectedItems,
  cardType,
  cardTitle,
  id,
  setFolderHistory,
  setFoldersAndFiles,
  setParentFolder,
  setPage,
  setExpandedImage,
  imageSrc,
}: ISelectDoubleClick) => {
  if (clickTimeout) {
    clearTimeout(clickTimeout);
    if (setClickTimeout) setClickTimeout(null);
  }
  if (cardType === "Folder") {
    setPage(1);
    setFoldersAndFiles([]);
    handleFolderChange({
      cardType,
      setParentFolder,
      setPage,
      setFoldersAndFiles,
      setFolderHistory,
      id,
      cardTitle,
    });
    setSelectedItems && setSelectedItems([]);
  }
  if (cardType === "Image" || cardType === "Video") {
    setExpandedImage && setExpandedImage({
      source: imageSrc || '',
      name: cardTitle,
      id,
      extension: extension || '',
      pathInStorage: pathInStorage || ''
    });
  }
  if (cardType === "PDF" || cardType === "Word" || cardType === "Text") {
    const link = document.createElement("a");
    link.href = imageSrc ? imageSrc : "";
    link.download = cardTitle;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
