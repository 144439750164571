import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import { IImageIdeaVM } from "../../../utils/interfaces/ICopilot";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const useGetImageIdeas = () => {
  return useQuery<IBaseData<IImageIdeaVM[]>>({
    queryKey: ["useGetImageIdeas"],
    queryFn: async () => {
      const response = await makeRequest.get(`/SuggestedImages/ImageIdeas/`);
      return response as IBaseData<IImageIdeaVM[]>;
    },
  });
};
