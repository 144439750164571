import React, { useEffect, useState } from "react";
import TextsSection from "../../components/brand/TextsSection";
import Strategy from "../../components/brand/sections/Strategy";
import Voice from "../../components/brand/sections/Voice";
import Identity from "../../components/brand/sections/Identity";
import Audience from "../../components/brand/sections/Audience";
import Personas from "../../components/brand/sections/Personas";
import MainInfo from "../../components/brand/sections/MainInfo";
import Colors from "../../components/brand/sections/Colors";
import PageHeading from "../../components/PageHeading";
import SButton from "../../design-system/SButton";
import CreateCustomFieldButton from "./customFields/CreateCustomFieldButton";
import CreateCustomFieldModal from "./customFields/CreateCustomFieldModal";
import { BrandingSectionName, ISingleCustomFieldVM } from "../../utils/interfaces/IBrand";
import { useBrandStore } from "../../store/brandStore";
import CustomField from "./customFields/CustomField";
import { BrandConfirmRegenerateSections } from "../../components/brand/BrandConfirmRegenerateSections";
import { useBrandHelperFunctions } from "../../components/brand/hooks/useBrandHelperFunctions";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";

function BrandBook() {
  const [isCustomFieldModalOpen, setIsCustomFieldModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState<ISingleCustomFieldVM[]>([]);
  const { currentBrand } = useBrandStore();
  const [activeField, setActiveField] = useState<
    ISingleCustomFieldVM | undefined
  >();
  useEffect(() => {
    if (currentBrand) {
      setCustomFields(currentBrand.customFields);
    }
  }, [currentBrand]);

  const half = Math.ceil(customFields.length / 2);
  const firstHalf = customFields.slice(0, half);
  const secondHalf = customFields.slice(half);

  const [confirmRegenerate, setConfirmRegenerate] = useState<BrandingSectionName | false>(false);
  const { handleRegenerateSection } = useBrandHelperFunctions();
  const { personalInfo } = useAccountStore();
  return (
    <>
      <CreateCustomFieldModal
        isOpen={isCustomFieldModalOpen}
        onClose={(p) => {
          setIsCustomFieldModalOpen(p);
          setActiveField(undefined);
        }}
        field={activeField}
      />
      {confirmRegenerate !== false && (
        <BrandConfirmRegenerateSections
          openModal={confirmRegenerate}
          setOpenModal={setConfirmRegenerate}
          onConfirm={() => {
            confirmRegenerate && handleRegenerateSection(confirmRegenerate)
            setConfirmRegenerate(false);
          }}
        />
      )}
      <div className="mt-[20px] mb-[24px] pr-[16px] max-md:pr-0">
        <div className="flex gap-[16px] max-md:flex-col">
          <div className="flex flex-col gap-[16px] min-w-[50%] max-w-[50%] max-md:flex-col max-md:min-w-full max-md:w-full">
            <MainInfo />
            <Colors setConfirmRegenerate={setConfirmRegenerate} />
            <Personas />
            {firstHalf.map((field) => (
              <CustomField
                setActiveField={setActiveField}
                field={field}
                setIsCustomFieldModalOpen={setIsCustomFieldModalOpen}
              />
            ))}
            {secondHalf.length > firstHalf.length && (
              <CreateCustomFieldButton
                openModal={() => setIsCustomFieldModalOpen(true)}
              />
            )}
          </div>
          <div className="flex flex-col gap-[16px] min-w-[50%] max-w-[50%] max-md:flex-col max-md:min-w-full max-md:w-full">
            <Strategy />
            <Voice />
            <Identity />
            <Audience />
            {secondHalf.map((field, index) => (
              <CustomField
                field={field}
                setActiveField={setActiveField}
                setIsCustomFieldModalOpen={setIsCustomFieldModalOpen}
              />
            ))}
            {secondHalf.length <= firstHalf.length && personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
              <CreateCustomFieldButton
                openModal={() => setIsCustomFieldModalOpen(true)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BrandBook;
