import { useEffect, useState } from "react";
import { useGetNotifications } from "../../../api/notifications/get";
import useScrollStatus from "../../../hooks/useScrollStatus";
import useNotificationSocketStore from "../../../store/notificationsStore";
import { useDeleteNotification } from "../../../api/notifications/delete";
import { useRespondFromNotification } from "./useRespondFromNotification";
import { useValueByType } from "./useValueByType";
import { toast } from "../../../hooks/toast";

export const useNotifications = () => {
  const {
    liveMessages,
    removeLiveMessage,
    page,
    setPage,
    notifications,
    setNotifications,
  } = useNotificationSocketStore();
  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(null);
  const { data, isLoading, isFetching } = useGetNotifications({
    page: page,
    pageSize: 20,
  });
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { handleClick } = useRespondFromNotification();
  const { getValue } = useValueByType();
  const { mutateAsync: deleteNotification, isPending } =
    useDeleteNotification();

  useEffect(() => {
    if (
      !isFetching &&
      initialLoadComplete &&
      !isLoading &&
      data &&
      data.data.showNext
    ) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
      }
    }
  }, [isScrolledToBottom, isFullyOnScreen]);

  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      setNotifications([...notifications, ...data.data.notifications]);
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!initialLoadComplete) {
      setNotifications([]);
    }
  }, [initialLoadComplete, setNotifications]);

  useEffect(() => {
    if (liveMessages.length > 0) {
      const lastMessage = liveMessages[liveMessages.length - 1];
      setNotifications([lastMessage, ...notifications]);
      removeLiveMessage(lastMessage.id);
    }
  }, [liveMessages]);

  const handleDeleteNotification = (id: number) => {
    setNotifications([]);
    deleteNotification({ id: id })
      .then(() => {
        toast("Notification deleted successfully", "success");
      })
      .catch((res) => {
        toast(res.message, "error");
      });
  };

  return {
    handleClick,
    getValue,
    handleDeleteNotification,
    notifications,
    isLoading,
    isFetching,
    isPending,
    data,
  };
};
