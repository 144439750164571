import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";
import { ICreateBrandMessage } from "./ICreateBrandChatInterface";

export const useUpdateCreateBrandMessages = ({
  messages,
  setMessages,
}: {
  messages: ICopilotMessage[];
  setMessages: React.Dispatch<React.SetStateAction<ICopilotMessage[]>>;
}) => {
  const updateLastMessage = (obj: ICreateBrandMessage) => {
    const tmp = [...messages];
    const lastMessage = { ...tmp[tmp.length - 1] };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        (lastMessage as any)[key] = obj[key as keyof ICreateBrandMessage];
      }
    }
    tmp[tmp.length - 1] = lastMessage;
    setMessages(tmp);
  };
  return { updateLastMessage };
};
