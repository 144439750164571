import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useUpdateContentLanguage = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleBrandVM>,
    Error,
    {
      brandId: number,
      contentLanguageId: number | null
    }
  >({
    mutationFn: ({ brandId, contentLanguageId}) =>
      makeRequest.put(`/Organizations/${brandId}/ContentLanguage?language=${contentLanguageId}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
    },
  });
};
