import SButton from "../../design-system/SButton";
import { TeamPackageStatus } from "../../utils/enums/Enums";
import { determineColor } from "./functions/determineColor";
import { BillingInfoTexts } from "./BillingInfoTexts";

export interface IBillingInfo {
  type: TeamPackageStatus;
  handleClick: () => void;
  isTrial: boolean;
  className?: string;
}

export const BillingInfo = ({ type, handleClick, isTrial, className }: IBillingInfo) => {
  const bgColor = determineColor(type, isTrial);
  return (
    <div
      className={`${className} border border-gray-200 w-full flex justify-between items-center py-4 px-[20px] rounded-lg ${bgColor} max-md:flex-wrap max-md:items-start max-md:gap-[12px]`}
    >
      <div className="flex gap-3 items-start">
        <BillingInfoTexts type={type} isTrial={isTrial} />
      </div>
      <SButton
        onClick={handleClick}
        size="sm"
        type={
          type === TeamPackageStatus.CANCELED
            ? "primary"
            : type === TeamPackageStatus.PAUSED || isTrial
              ? "secondaryGray"
              : "secondaryColor"
        }
        className={`min-w-fit ${type === TeamPackageStatus.CANCELED && "bg-error-600 hover:bg-error-700 border-error-600"}`}
      >
        {type === TeamPackageStatus.CANCELED
          ? "Activate"
          : type === TeamPackageStatus.PAUSED || isTrial
            ? "Manage Plan"
            : "Upgrade Plan"}
      </SButton>
    </div>
  );
};
