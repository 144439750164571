import { useGetWorkspaceUsers } from "../../api/workspaces/users/get";
import { BrandInviteWorkspaceMembersRow } from "./BrandInviteWorkspaceMembersRow";

export const BrandInviteWorkspaceMembers = ({
  brandId,
}: {
  brandId?: number;
}) => {
  const { data: workspaceMembers } = useGetWorkspaceUsers();

  const notJoinedMembers = workspaceMembers?.data
    .filter((member) => {
      return !member.organizationUsers.find(
        (orgMember) => orgMember.organizationId === brandId
      );
    })
    .map((member) => <BrandInviteWorkspaceMembersRow member={member} />);
  return (
    <>
      {notJoinedMembers &&notJoinedMembers.length > 0 && (
        <div className="w-full mt-2 h-[300px] flex flex-col">
          <div className="w-full px-6 flex justify-between items-center bg-gray-50 text-xs-m border-t border-x rounded-t-[12px]">
            <h1 className="py-[13px] w-full text-start text-gray-600 border-gray-200">
              Member
            </h1>
            <h1 className="py-[13px] w-1/2 text-start text-gray-600 border-gray-200">
              Role
            </h1>
          </div>
          <div className="w-full h-full border border-gray-200 rounded-b-[12px] overflow-y-auto">
            {notJoinedMembers}
          </div>
        </div>)
      }
    </>
  );
};
