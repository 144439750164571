export const removeExtension = (name: string, extension?: string) => {
  extension = extension?.toLowerCase();
  name = name.includes(".jpg") ? name.split(".jpg")[0] : name;
  name = name.includes(".jpeg") ? name.split(".jpeg")[0] : name;
  name = name.includes(".png") ? name.split(".png")[0] : name;
  name = name.includes(".pdf") ? name.split(".pdf")[0] : name;
  name = name.includes(".mp4") ? name.split(".mp4")[0] : name;
  name = name.includes(".mov") ? name.split(".mov")[0] : name;
  name = name.includes(".avi") ? name.split(".avi")[0] : name;
  name = name.includes(".doc") ? name.split(".doc")[0] : name;
  name = name.includes(".docx") ? name.split(".docx")[0] : name;
  
  return name;
};