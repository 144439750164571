import { useState } from "react";
import { BrandInviteMemberModal } from "../../brand/BrandInviteMemberModal";
import { useNotifications } from "../../notifications/hooks/useNotifications";
import { RenderNotifications } from "../../notifications/RenderNotifications";
import SButton from "../../../design-system/SButton";

function TeamActivity() {
  const [isBrandInviteModalOpen, setIsBrandInviteModalOpen] = useState(false);
  const {
    handleClick,
    getValue,
    handleDeleteNotification,
    notifications,
    isLoading,
    isFetching,
    isPending,
    data,
  } = useNotifications();
  return (
    <>
      {isBrandInviteModalOpen && (
        <BrandInviteMemberModal
          inviteModal={isBrandInviteModalOpen}
          setInviteModal={setIsBrandInviteModalOpen}
          isWorkspaceOnly={true}
        />
      )}
      <div className="w-full h-auto bg-base-white border border-gray-200 shadow-xs rounded-[12px] overflow-hidden">
        <div className="max-h-[64px] px-[16px] py-[20px] bg-gray-25 flex justify-between items-center border-b border-b-gray-200 relative z-[1]">
          <p className="text-md-m text-gray-900">Team Activity</p>
        </div>
        <div
          className={`
        ${data && notifications.length === 0 && "flex justify-center items-center"}
        w-full h-[calc(438px-64px)] bg-base-white p-[12px] flex flex-col gap-[6px]  overflow-auto`}
        >
          {data && notifications.length === 0 && (
            <div className="flex justify-center items-center flex-col">
              <p className="text-md-m">There is no team activity</p>
              {/* <SButton onClick={() => handleOpenPost({})} className="mt-[12px]">
                Create post
              </SButton>
              <p className="text-xs text-gray-600 my-[4px]">or</p> */}
              <SButton
                className="mt-[12px]"
                onClick={() => setIsBrandInviteModalOpen(true)}
                type="secondaryColor"
              >
                Invite user
              </SButton>
            </div>
          )}
          <RenderNotifications
            handleClick={handleClick}
            getValue={getValue}
            handleDeleteNotification={handleDeleteNotification}
            notifications={notifications}
            isLoading={isLoading}
            isFetching={isFetching}
            isPending={isPending}
            emptyState={false}
          />
        </div>
      </div>
    </>
  );
}

export default TeamActivity;
