import React from "react";
import { ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { IBaseData } from "../../utils/interfaces/IBase";
import SRadio from "../../design-system/SRadio";
import SButton from "../../design-system/SButton";
import {
  faCircle,
  faPlus,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllBrands } from "../../api/brand/getAll";
import { usePutSwitchBrand } from "../../api/brand/switch/put";
import { OrganizationType } from "../../utils/enums/Enums";

function BrandSelector({
  setIsBrandSelectorOpen,
  currentBrand,
  setIsMobileMenuOpen,
}: {
  setIsBrandSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentBrand: IBaseData<ISingleBrandVM>;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { mutateAsync: switchBrand, isPending } = usePutSwitchBrand();
  const { data: brands } = useGetAllBrands({ includeAllWorkspaces: false });
  return (
    <div
      className={`
      ${brands ? (brands.data.length > 1 ? "" : "px-0") : "px-0"} 
      flex flex-col items-center w-full absolute top-[100%] left-0  bg-base-white px-[8px] rounded-b-[12px] shadow-lg
      `}
    >
      <div className="w-full h-[1px] bg-gray-200 my-[10px]" />
      <div className="flex flex-col w-full gap-[10px] max-h-[200px] overflow-auto">
        {brands &&
          brands.data.map(
            (item) =>
              item.id !== currentBrand.data.id && (
                <div
                  onClick={() => {
                    switchBrand({ id: item.id });
                    setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                  }}
                  className="bg-gray-25 border border-gray-200 py-[8px] px-[10px] rounded-[8px] flex justify-between items-center cursor-pointer"
                >
                  <div className="flex items-center gap-[10px]">
                    <img
                      className="max-w-[32px] min-w-[32px] w-full max-h-[32px] object-contain rounded-[6px]"
                      src={item.logo}
                      alt="logo"
                    />
                    <div>
                      <p className="text-xs text-gray-400">
                        {item.organizationType === OrganizationType.COMPANY &&
                          "Company"}
                        {item.organizationType === OrganizationType.PERSONAL &&
                          "Personal"}
                      </p>
                      <p className="text-sm-sb text-gray-700">{item.name}</p>
                    </div>
                  </div>
                  <SButton
                    icon={isPending ? faRefresh : faCircle}
                    iconProps={{ spin: isPending }}
                    size="sm"
                    type="tertiaryGray"
                  />
                </div>
              ),
          )}
      </div>
      <SButton
        onClick={() => {
          setIsBrandSelectorOpen(false);
          setIsMobileMenuOpen && setIsMobileMenuOpen(false);
          navigate("/create-brand");
        }}
        type="secondaryGray"
        rIcon={faPlus}
        size="sm"
        className={`
           ${brands ? (brands.data.length > 1 ? "mt-[18px] mb-[18px]" : "mt-0 mb-[8px]") : "mt-[18px] mb-[18px]"} 
          `}
      >
        Create Brand
      </SButton>
    </div>
  );
}

export default BrandSelector;
