import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TeamPackageStatus } from "../../utils/enums/Enums";
import {
  faClock,
  faExclamationCircle,
  faSmileBeam,
} from "@fortawesome/pro-regular-svg-icons";

export const BillingInfoIcons = ({
  type,
  isTrial,
}: {
  type: TeamPackageStatus;
  isTrial: boolean;
}) => {
  return (
    <>
      {type === TeamPackageStatus.CANCELED && (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-error-500"
        />
      )}
      {type === TeamPackageStatus.PAUSED && (
        <FontAwesomeIcon icon={faClock} className="text-warning-500" />
      )}
      {type === TeamPackageStatus.ACTIVE && isTrial && (
        <FontAwesomeIcon icon={faClock} className="text-warning-500" />
      )}
      {type === TeamPackageStatus.ACTIVE && !isTrial && (
        <FontAwesomeIcon icon={faSmileBeam} className="text-success-500" />
      )}
    </>
  );
};
