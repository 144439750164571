import React, { useEffect, useState } from "react";

import PreviewSide from "../components/creation/previews/PreviewSide";
import SButton from "../design-system/SButton";
import { faComment, faDownload, faX } from "@fortawesome/pro-regular-svg-icons";
import CreationHeader from "../components/creation/CreationHeader";
import Brief from "../components/creation/Tabs/Brief";
import { useInitializePost } from "../components/creation/hooks/useInitializePost";
import { useCreationStore } from "../store/creationStore";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import useLockBodyScroll from "../hooks/useLockBodyScroll";
import Channels from "../components/creation/Tabs/Channels";
import Media from "../components/creation/Tabs/Media";
import { useCreationLibraryHelperFunctions } from "../components/creation/Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";
import { OrganizationUserRole, PostStatus } from "../utils/enums/Enums";
import CommentsModal from "../components/creation/CommentsModal";
import VideoSelectionAgreement from "../components/creation/modals/VideoSelectionAgreement";
import PublishedTab from "../components/creation/Tabs/PublishedTab";
import { getQuery } from "../hooks/functions/queries";
import Frames from "../components/creation/Tabs/Frames";
import { useAccountStore } from "../store/accountStore";
import SpeechRecognition from "react-speech-recognition";

const Creation = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  useInitializePost();
  const { activeTab, setActiveTab, activeSoc, tabsOverlay, postData } =
    useCreationStore();
  useLockBodyScroll(isOpen);
  const { downloadMediasFromActiveSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();

  const [isCommentsOpen, setIsCommentsOpen] = useState(
    getQuery("open_comments") === "true" ? true : false,
  );

  useEffect(() => {
    if (isPostPublishedOrScheduled()) {
      setActiveTab("published");
    } else {
      if (activeTab === "published") setActiveTab("brief");
    }
  }, [postData, activeSoc]);
  const { personalInfo } = useAccountStore();
  const [isUpdatedByCopilot, setIsUpdatedByCopilot] = useState(false);
  return (
    <>
      <VideoSelectionAgreement />
      <Drawer
        open={isOpen}
        onClose={async () => {
          await SpeechRecognition.stopListening();
          onClose();
        }}
        direction="bottom"
        className="min-h-[95dvh] rounded-[24px]"
        lockBackgroundScroll={true}
      >
        <div className="flex flex-col gap-[58px] absolute top-[20px] right-[20px] z-[1000] max-md:top-[90px]">
          <div className="flex gap-[6px]">
            {isCommentsOpen && (
              <CommentsModal
                isOpen={isCommentsOpen}
                onClose={() => setIsCommentsOpen(false)}
              />
            )}
            <SButton
              id="post__comments__chat-opener"
              onClick={() => setIsCommentsOpen((p) => !p)}
              icon={faComment}
              size="md"
              type="secondaryGray"
            />
            <SButton
              icon={faDownload}
              onClick={downloadMediasFromActiveSocialNetworkType}
              size="md"
              type="secondaryGray"
            />
            <SButton
              icon={faX}
              size="md"
              type="secondaryGray"
              onClick={onClose}
            />
          </div>
          <div className="relative flex flex-col gap-[6px]"></div>
        </div>
        <div className="flex h-full w-full rounded-t-[24px] max-md:flex-col-reverse">
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <div className="w-[60%] h-[100%] bg-white border border-gray-200 rounded-tl-[24px] flex flex-col mb-[20px] max-md:w-full max-md:rounded-tl-[0px] max-md:border-0 max-md:m-0">
              <CreationHeader />
              <div
                className={`${activeTab !== "elements" ? (tabsOverlay ? "py-[20px]" : "py-[20px]") : "py-0"} ${activeTab !== "elements" && "px-[36px]"}  h-full overflow-hidden relative max-md:p-0`}
              >
                {tabsOverlay && (
                  <div
                    className={`absolute w-full h-[calc(100%+20px)] top-[-20px] left-0 bg-[rgba(152,152,152,0.8)] z-[100]`}
                  />
                )}
                {activeTab === "brief" && (
                  <Brief setIsUpdatedByCopilot={setIsUpdatedByCopilot} />
                )}
                {activeTab === "accounts" && <Channels />}
                {activeTab === "media" && <Media />}
                {activeTab === "elements" && <Frames />}
                {activeTab === "published" && <PublishedTab />}
              </div>
            </div>
          )}
          <PreviewSide
            isUpdatedByCopilot={isUpdatedByCopilot}
            setIsUpdatedByCopilot={setIsUpdatedByCopilot}
          />
        </div>
      </Drawer>
    </>
  );
};
export default Creation;
