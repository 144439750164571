import React from "react";

function FrameViewItem({
  image,
  className,
  isActive = false,
  isDashed = false,
}: {
  isDashed?: boolean;
  className?: string;
  image?: string;
  isActive?: boolean;
}) {
  return (
    <div
      className={`${className} ${isActive && "border-[2px] border-brand-600 rounded-[18px] p-[4px]"} ${isDashed ? "border-dashed" : "border-solid"}`}
    >
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(3px)",
        }}
        className={` w-full h-full rounded-[12px] flex justify-center items-center overflow-hidden`}
      >
        {image ? (
          <img src={image} className="w-full h-full object-cover" alt="" />
        ) : (
          <p className="text-sm-sb text-base-white">No frame</p>
        )}
      </div>
    </div>
  );
}

export default FrameViewItem;
