import React, { useEffect, useRef, useState } from "react";
import SBadge from "../../../../../design-system/SBadge";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useCreationStore } from "../../../../../store/creationStore";
import SButton from "../../../../../design-system/SButton";
import { CSSTransition } from "react-transition-group";
import DefaultState from "./DefaultState";
import StocksView from "./StocksView";
import GeneratedSelector from "./GeneratedSelector";
import SelectorTags from "./SelectorTags";
import Input from "./Input";
import { useHandleOutsideClick } from "../../../../../hooks/useHandleOutsideClick";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";
import { IStockImageVM } from "../../../../../utils/interfaces/IPost";
import { useGenerateStocks } from "./hooks/useGenerateStocks";
import { useGetWindowHeight } from "../../../../../hooks/useGetWindowHeight";

function HelperInput() {
  const [isOpen, setIsOpen] = useState(false);
  const { setTabsOverlay } = useCreationStore();
  const containerRef = useRef<any>(null);

  useEffect(() => {
    setTabsOverlay(isOpen);
  }, [isOpen]);
  const [activeTab, setActiveTab] = useState<
    "generator" | "stocks" | "search" | "selector"
  >("generator");
  const [activeStyleTag, setActiveStyleTag] = useState<ImageGenerationStyle>(1);

  useHandleOutsideClick({
    ref: containerRef,
    callBack: () => setIsOpen(false),
    excludedIds: ["expanded-image-modal"],
  });
  const [inputValue, setInputValue] = useState("");

  useGenerateStocks({ isOpen });
  const { windowHeight } = useGetWindowHeight();
  return (
    <div
      ref={containerRef}
      className={`
        absolute top-0 left-0 overflow-y-auto z-[101]
        transition-all duration-500 ease-in-out w-full ${windowHeight > 860 ? "h-fit" : isOpen ? "h-full" : "h-fit"}
        ${isOpen ? "max-w-[100%] 2xl:max-w-[800px]" : "max-w-[80%]"} 
        ${isOpen ? "py-[16px] mt-[14px] rounded-[8px] bg-base-white shadow-xl3" : "py-[0] mt-[0] rounded-[8px] bg-transparent shadow-none"}
      `}
    >
      <Input
        activeStyleTag={activeStyleTag}
        inputValue={inputValue}
        setInputValue={setInputValue}
        activeTab={activeTab}
        isOpen={isOpen}
        setActiveTab={setActiveTab}
        setIsOpen={setIsOpen}
      />
      {/* {activeTab !== "search" && ( */}
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="expand"
        unmountOnExit
      >
        <div className="expand-wrapper">
          {activeTab === "selector" && (
            <SelectorTags
              activeStyleTag={activeStyleTag}
              setActiveStyleTag={setActiveStyleTag}
            />
          )}

          {activeTab === "generator" && (
            <DefaultState
              setInputValue={setInputValue}
              setActiveTab={setActiveTab}
              activeStyleTag={activeStyleTag}
              setActiveStyleTag={setActiveStyleTag}
            />
          )}
          {activeTab === "stocks" && (
            <StocksView
              activeTab={activeTab}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          )}
          {activeTab === "search" && <></>}
          {activeTab === "selector" && <GeneratedSelector />}
        </div>
      </CSSTransition>
      {/* )} */}
    </div>
  );
}

export default HelperInput;
