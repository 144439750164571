import {
  faEllipsisVertical,
  faPencil,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useCopilotStore } from "../../store/copilotStore";
import { ICopilotSessionVM } from "../../utils/interfaces/ICopilot";
import SSmallMenu from "../../design-system/SSmallMenu";
import RenameSessionModal from "./modals/RenameSessionModal";
import DeleteAgreementModal from "./modals/DeleteAgreementModal";

function SessionItem({ session }: { session: ICopilotSessionVM }) {
  const { activeSession, setActiveSession, isWriting, setMessages } =
    useCopilotStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRenameOpen, setIsRenameOpen] = useState(false);
  const [isDeleteAgreementOpen, setIsDeleteAgreementOpen] = useState(false);
  const openerId = `session-item-menu-opener-${session.id}`;

  return (
    <>
      {isRenameOpen && (
        <RenameSessionModal
          actionSession={session}
          isOpen={isRenameOpen}
          onClose={() => {
            setIsRenameOpen(false);
          }}
        />
      )}
      {isDeleteAgreementOpen && (
        <DeleteAgreementModal
          actionSession={session}
          isOpen={isDeleteAgreementOpen}
          setIsOpen={setIsDeleteAgreementOpen}
        />
      )}
      <div
        onClick={() => {
          if (activeSession?.id !== session.id) {
            setMessages([]);
            setActiveSession(session);
          }
        }}
        className={`${isWriting ? "pointer-events-none" : "pointer-events-auto"} flex items-center justify-between cursor-pointer relative`}
      >
        <p
          title={session.name}
          className={`text-sm-m truncate ${session.id === activeSession?.id ? "text-brand-600" : "text-gray-600"}`}
        >
          {session.name}
        </p>
        <FontAwesomeIcon
          id={openerId}
          onClick={(e) => {
            e.stopPropagation();
            setIsMenuOpen(!isMenuOpen);
          }}
          icon={faEllipsisVertical}
          className="text-[12px] text-gray-500 p-[4px]"
        />
        <SSmallMenu
          className="left-[100%] z-[10]"
          isOpen={isMenuOpen}
          setIsOpen={(p) => setIsMenuOpen(p)}
          excludedId={openerId}
          data={[
            [
              {
                text: "Rename",
                icon: faPencil,
                handleClick: () => {
                  setIsRenameOpen(true);
                },
              },
              {
                text: "Delete",
                icon: faTrash,
                handleClick: () => {
                  setIsDeleteAgreementOpen(true);
                },
                color: "text-red-500",
              },
            ],
          ]}
        />
      </div>
    </>
  );
}

export default SessionItem;
