import { MouseEvent, useState } from "react";
import { usePutFolder } from "../../../api/library/folders/put";
import { useDeleteFolder } from "../../../api/library/folders/delete";
import { usePutFile } from "../../../api/library/files/put";
import { useDeleteFile } from "../../../api/library/files/delete";
import { usePutFoldersAndFiles } from "../../../api/library/foldersAndFiles/put";
import { useGetFolderById } from "../../../api/library/folders/get";
import { useZipAssets } from "../../../hooks/useZipAssets";
import { useLibraryStore } from "../../../store/libraryStore";

export interface IRenameFileOrFolderProps {
  id: number;
  type: "Folder" | "File";
  path?: string;
  extension?: string;
}

export interface IHandleFolderDownloadProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  folderId: number;
}

export interface IHandleDownloadProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  url: string;
  name: string;
}

export interface IHandleMoveIntoProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  fileIds: number[];
  folderIds: number[];
}

export interface IHandleMoveOutProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  fileIds: number[];
  folderIds: number[];
  parentId?: number | null;
}

export interface IHandleDeleteProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  id?: number;
  cardType?: "Folder" | "File";
}

export interface IHandleRenameProps {
  e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>;
  id?: number;
  cardType?: "Folder" | "File";
  path?: string;
  extension?: string;
}

export const useGridCardActions = ({ rawTitle }: { rawTitle?: string }) => {
  const { setFoldersAndFiles, setPage } = useLibraryStore();
  const [openRename, setOpenRename] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [value, setValue] = useState(rawTitle ? rawTitle : "");
  const [moveIntoModal, setMoveIntoModal] = useState(false);
  const { mutateAsync: getFolderById } = useGetFolderById();
  const [deleteAsset, setDeleteAsset] = useState<{
    id: number;
    type: "Folder" | "File";
  } | null>(null);
  const [renameAsset, setRenameAsset] =
    useState<IRenameFileOrFolderProps | null>(null);
  const [moveAssets, setMoveAssets] = useState<{
    fileIds: number[];
    folderIds: number[];
  } | null>(null);

  const { download } = useZipAssets();

  const { mutateAsync: updateFolder } = usePutFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFolder } = useDeleteFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: updateFile } = usePutFile({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFile } = useDeleteFile({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: moveAsset } = usePutFoldersAndFiles({
    setPage,
    setFoldersAndFiles,
  });

  const handleRename = ({
    e,
    id,
    cardType,
    path,
    extension,
  }: IHandleRenameProps) => {
    e.stopPropagation();
    if (cardType === "File") {
      id &&
        cardType &&
        path &&
        extension &&
        setRenameAsset({ id: id, type: cardType, path, extension });
    } else {
      id && cardType && setRenameAsset({ id: id, type: cardType });
    }
    setOpenRename(true);
  };

  const handleDelete = ({ e, id, cardType }: IHandleDeleteProps) => {
    e.stopPropagation();
    id && cardType && setDeleteAsset({ id: id, type: cardType });
    setOpenConfirmation(true);
  };

  const handleMoveOut = ({
    e,
    folderIds,
    fileIds,
    parentId,
  }: IHandleMoveOutProps) => {
    e.stopPropagation();
    moveAsset({
      folderIds,
      fileIds,
      parentFolderId: parentId ? parentId : null,
    });
  };

  const handleMoveInto = ({ e, fileIds, folderIds }: IHandleMoveIntoProps) => {
    e.stopPropagation();
    setMoveAssets({
      fileIds,
      folderIds,
    });
    setMoveIntoModal(true);
  };

  const handleDownload = async ({ e, url, name }: IHandleDownloadProps) => {
    e.stopPropagation();
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleFolderDownload = async ({
    e,
    folderId,
  }: IHandleFolderDownloadProps) => {
    e.stopPropagation();
    const { data } = await getFolderById({ id: folderId });
    download(data.files, []);
  };

  return {
    openRename,
    setOpenRename,
    openConfirmation,
    setOpenConfirmation,
    value,
    setValue,
    handleRename,
    handleDelete,
    updateFolder,
    deleteFolder,
    updateFile,
    deleteFile,
    handleDownload,
    handleMoveOut,
    handleMoveInto,
    moveIntoModal,
    setMoveIntoModal,
    handleFolderDownload,
    deleteAsset,
    setDeleteAsset,
    renameAsset,
    moveAssets,
  };
};
