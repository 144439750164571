import { useState } from "react";

export interface SelectableItem {
  id: number;
  extension?: string;
}

export function useSelectableItems<T extends SelectableItem>(items: T[]) {
  const [selectedItems, setSelectedItems] = useState<
    { id: number; isFolder: boolean }[]
  >([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(
        items.map((item) => {
          return {
            id: item.id,
            isFolder: !item.extension ? true : false,
          };
        })
      );
    }
    setSelectAll(!selectAll);
  };

  const handleSelectItem = (
    id: number,
    isFolder: boolean,
    multiple?: { id: number; isFolder: boolean }[]
  ) => {
    !multiple &&
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.some(
          (item) => item.id === id && item.isFolder === isFolder
        )
          ? prevSelectedItems.filter(
              (item) => item.id !== id || item.isFolder !== isFolder
            )
          : [...prevSelectedItems, { id, isFolder }]
      );

    multiple &&
      setSelectedItems((prevSelectedItems) => {
        return [...prevSelectedItems, ...multiple];
      });
  };

  return {
    selectedItems,
    selectAll,
    handleSelectAll,
    handleSelectItem,
    setSelectedItems,
  };
}
