import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

export const FieldToolTip = ({
  toolTipContent,
}: {
  toolTipContent?: string;
}) => {
  return (
    <>
      {toolTipContent && (
        <>
          <FontAwesomeIcon
            className="absolute right-0 translate-x-2 top-1/2 -translate-y-1/2 text-gray-700"
            size="sm"
            data-tooltip-id="tooltip-content-language"
            icon={faCircleQuestion}
          />
          <Tooltip
            id="tooltip-content-language"
            place="top"
            style={{ zIndex: 1 }}
            disableStyleInjection
            className="text-sm w-[200px]"
          >
            {toolTipContent}
          </Tooltip>
        </>
      )}
    </>
  );
};
