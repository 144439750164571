import React, { useState } from "react";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import SButton from "../../../design-system/SButton";
import { PostStatus, SocialNetworkType } from "../../../utils/enums/Enums";
import { usePutRemoveSchedule } from "../../../api/creation/removeSchedule/put";
import { useUpdatePost } from "../hooks/useUpdatePost";
import RemoveScheduleAgreement from "../modals/RemoveScheduleAgreement";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../../store/creationStore";

function PublishedTab() {
  const {
    getActiveSocialNetworkPost,
    isPostPublished,
    getSocialNetworkNameByType,
  } = useCreationHelperFunctions();
  const [
    isRemoveScheduleAgreementModalOpen,
    setIsRemoveScheduleAgreementModalOpen,
  ] = useState(false);
  const { activeSoc, setActiveSoc } = useCreationStore();
  const socMedias = [
    {
      icon: faFacebook,
      color: "#0866FF",
      type: SocialNetworkType.FACEBOOK,
    },
    {
      icon: faInstagram,
      color: "#E1306C",
      type: SocialNetworkType.INSTAGRAM,
    },
    {
      icon: faXTwitter,
      color: "#000000",
      type: SocialNetworkType.TWITTER,
    },
    {
      icon: faLinkedin,
      color: "#0077B5",
      type: SocialNetworkType.LINKEDIN,
    },
    {
      icon: faPinterest,
      color: "#E60023",
      type: SocialNetworkType.PINTEREST,
    },
  ];
  return (
    <>
      <RemoveScheduleAgreement
        isOpen={isRemoveScheduleAgreementModalOpen}
        setIsOpen={setIsRemoveScheduleAgreementModalOpen}
      />
      {getActiveSocialNetworkPost() && (
        <div className="overflow-hidden rounded-[12px] border-[1px] border-gray-200">
          <p className="px-[24px] py-[16px] border-b border-b-gray-200 text-md-m text-gray-600">
            Your post has been {isPostPublished() ? "published" : "scheduled"}{" "}
            on{" "}
            {getSocialNetworkNameByType(
              getActiveSocialNetworkPost()!.socialNetworkType,
            )}{" "}
            🎉
          </p>
          <div className="p-[24px] flex flex-col gap-[12px]">
            {isPostPublished() ? (
              <SButton
                onClick={() => {
                  window.open(
                    getActiveSocialNetworkPost()!.postUrl as string,
                    "_blank",
                  );
                }}
                type="secondaryColor"
                className="w-fit"
              >
                View on{" "}
                {getSocialNetworkNameByType(
                  getActiveSocialNetworkPost()!.socialNetworkType,
                )}
              </SButton>
            ) : (
              <SButton
                onClick={() => {
                  setIsRemoveScheduleAgreementModalOpen(true);
                }}
                type="secondaryColor"
                className="w-fit"
              >
                Remove schedule
              </SButton>
            )}
            <p className="text-sm text-gray-600">
              Publish your post on other social medias:
            </p>
            <div className="flex border-[1px] border-gray-300 w-fit rounded-[8px] h-[40px] bg-base-white">
              {socMedias
                .filter((item) => item.type !== activeSoc)
                .map((item, i) => (
                  <div
                    key={item.type}
                    onClick={() => {
                      setActiveSoc(item.type);
                    }}
                    className={`hover:bg-gray-50  ${i !== 0 && "border-l-[1px]"} flex cursor-pointer border-l-gray-300 justify-center  h-auto w-[44px] items-center`}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      color={item.color}
                      fontSize={"18px"}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PublishedTab;
