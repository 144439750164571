import {
  SocialNetworkCharacterLimit,
  SocialNetworkType,
} from "../../../utils/enums/Enums";

export const CharacterCounter = ({
  text,
  activeSoc,
}: {
  text: string;
  activeSoc: SocialNetworkType;
}) => {
  const limit =
    SocialNetworkCharacterLimit[
      SocialNetworkType[activeSoc] as keyof typeof SocialNetworkCharacterLimit
    ];
  return (
    <>
      {limit - text.length <= 100 && (
        <div
          className={`mt-3 leading-none w-fit text-xs ${text.length > limit ? "border-error-100 bg-error-100" : "border-gray-200 bg-transparent"}  border px-2 py-1 rounded-md`}
        >
          {text.length <= limit && (
            <p className="text-xs">{limit - text.length}</p>
          )}
          {text.length > limit && (
            <p className="text-xs text-error-500">
              -{(limit - text.length) * -1}
            </p>
          )}
        </div>
      )}
    </>
  );
};
