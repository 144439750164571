import { useEffect } from "react";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { ICreateBrandMessage } from "./ICreateBrandChatInterface";
import { CopilotRole } from "../../../utils/enums/Enums";
import { useUpdateCreateBrandMessages } from "./useUpdateCreateBrandMessages";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useFillChat = ({
  activeSession,
  setChatResponse,
  chatResponse,
  setMessages,
  messages,
  disabled,
  setActiveSession,
}: {
  disabled: boolean;
  setActiveSession: React.Dispatch<
    React.SetStateAction<ICopilotSessionVM | null>
  >;
  activeSession: ICopilotSessionVM | null;
  messages: ICopilotMessage[];
  chatResponse: string;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setMessages: React.Dispatch<React.SetStateAction<ICopilotMessage[]>>;
}) => {
  const { updateLastMessage } = useUpdateCreateBrandMessages({
    messages,
    setMessages,
  });
  const { refetch: refetchCopilotMessages, isLoading } = useGetCopilotChat({
    sessionId: activeSession?.id,
  });

  useEffect(() => {
    if (activeSession) {
      refetchCopilotMessages().then((res) => {
        let tmp: ICopilotMessage[] = [];
        // TODO: MOVXSNA KOMENTARI
        res!.data!.data.map((item) => {
          tmp.push({
            role: item.role,
            content: item.content,
            files: item.files,
          });
        });
        setMessages(tmp);
      });
    }
  }, [activeSession]);

  // render AI Message
  // useEffect(() => {
  //   if (
  //     chatResponse &&
  //     chatResponse.length > 0 &&
  //     messages[messages.length - 1].role === CopilotRole.ASSISTANT
  //   ) {
  //     let lastMessage = messages[messages.length - 1];
  //     if (chatResponse)
  //       updateLastMessage({
  //         content: chatResponse,
  //         json: JSON.parse(chatResponse),
  //         isSuccess: true,
  //         role: lastMessage.role,
  //       });
  //   }
  // }, [chatResponse, disabled]);
};
