import { faCheck, faCopy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import logo from "../../assets/images/logos/stori-s-square.svg";
import { ICopilotMessage } from "../../utils/copilot-base/ICopilotBase";
import Markdown from "react-markdown";
function AIMessage({ msg }: { msg: ICopilotMessage }) {
  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <div className="h-fit flex gap-[12px] items-center relative group">
      <div className="flex gap-[12px]">
        <img
          src={logo}
          alt="copilot-logo"
          className="w-[40px] h-[40px] rounded-full"
        />
        <div
          className={`bg-base-white flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[556px] 
        w-fit stori-markdown-styles`}
        >
          {!msg.content && (
            <div className="typing__animation">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          <pre
            className="whitespace-pre-line break-words text-md text-gray-900"
            ref={textRef}
          >
            <Markdown>{msg.content?.Message}</Markdown>
          </pre>
        </div>
      </div>
      <FontAwesomeIcon
        icon={copied ? faCheck : faCopy}
        onClick={() => {
          if (!copied && textRef.current) {
            navigator.clipboard.writeText(textRef.current.innerText);
            setCopied(true);
          }
        }}
        className={`${copied ? "text-success-600 cursor-default" : "text-gray-600 cursor-pointer"} hidden group-hover:block`}
      />
    </div>
  );
}

export default AIMessage;
