import React, { useEffect, useState } from "react";
import SModal from "../../../design-system/SModal";
import { useCreationStore } from "../../../store/creationStore";
import { useCreationLibraryHelperFunctions } from "../Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { isVideo } from "../../Library/hooks/getFileType";
import { toast } from "../../../hooks/toast";

function VideoSelectionAgreement() {
  const {
    videoSelectionAgreementModalOpen,
    setVideoSelectionAgreementModalOpen,
  } = useCreationStore();
  const { selectMedia, insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (videoSelectionAgreementModalOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [videoSelectionAgreementModalOpen]);

  return (
    <>
      {videoSelectionAgreementModalOpen && (
        <SModal
          title={`${isVideo(videoSelectionAgreementModalOpen.file.url) ? "Select video" : "Select Image"} `}
          subtitle={
            isVideo(videoSelectionAgreementModalOpen.file.url)
              ? `If you select video, all images will be deselected`
              : `If you select an image, the video will be deselected`
          }
          type="warning"
          isOpen={isOpen}
          setIsOpen={(p) => setIsOpen(p)}
          handleSubmit={() => alert("asd")}
          handleCancel={() => setVideoSelectionAgreementModalOpen(null)}
          actionButtonText={"Confirm & Select"}
          actionButtonProps={{
            onClick: () => {
              if (videoSelectionAgreementModalOpen.forAll) {
                insertMediaForAllSocialNetworkType(
                  videoSelectionAgreementModalOpen.file,
                  true
                ).catch((e) => {
                  toast(e?.response?.data?.message);
                });
              } else {
                selectMedia(videoSelectionAgreementModalOpen.file, true);
              }
              setVideoSelectionAgreementModalOpen(null);
            },
            className: "w-[400px]",
          }}
        />
      )}
    </>
  );
}

export default VideoSelectionAgreement;
