import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { ITokenVM } from "../../../../utils/interfaces/IToken";
import { IBaseData } from "../../../../utils/interfaces/IBase";

export const usePostCanvaLInk = () => {
  return useMutation<IBaseData<ITokenVM>, Error, { canvaToken: string }>({
    mutationFn: ({ canvaToken }) =>
      makeRequest.post(`/Canva/LinkAccount?canvaToken=${canvaToken}`),
  });
};
