import React, { useState } from "react";
import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";
import AgentInfo from "./AgentInfo";
//@ts-ignore
import jason from "../.../../../../../assets/images/agents/jason.png";

const ToolMessage = ({ msg }: { msg: ICopilotMessage }) => {
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <div className="h-fit flex gap-[12px] relative">
        <AgentInfo
          logo={jason}
          agentName={"Jason"}
          agentDescription={
            "Hi there, I'm Jason!" +
            "\n \n" +
            "- I can format, change, adapt text\n" +
            "- I can generate images\n" +
            "- I can work on multiple social media\n" +
            "- I know your brand already\n" +
            "- I can recognize image and video content\n" +
            "- I can perform actions while talking with you \n \n" +
            "Just tell me what you need, and I'm happy to help!"
          }
          agentRole={"Content Creator"}
        >
          <img
            src={jason}
            alt="jason-avatar"
            className="w-[40px] h-[40px] rounded-full cursor-pointer"
          />
        </AgentInfo>
        <div
          className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] w-full`}
        >
          {msg.files &&
            msg.files.map((file, index) => (
              <div
                key={index}
                className="w-full max-w-[400px] mb-[16px] last:mb-0"
              >
                <img
                  onClick={() => setExpandedImage(file.url)}
                  src={file.url}
                  alt={`Generated image ${index + 1}`}
                  className="w-full h-auto rounded-[8px] cursor-pointer"
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ToolMessage;
