import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ISSelectOpener extends React.HTMLAttributes<HTMLDivElement> {
  onOpen: () => void;
  icon?: IconProp;
  image?: string;
  text: string;
  className?: string;
}

function SSelectOpener({
  icon,
  className,
  onOpen,
  text,
  image,
  ...props
}: ISSelectOpener) {
  return (
    <div
      onClick={onOpen}
      className={`${className} cursor-pointer h-[46px] px-[16px] flex items-center justify-between bg-white rounded-lg border-gray-200 border-[1px] min-w-[170px]`}
      {...props}
    >
      <div className="flex items-center">
        {image && <img src={image} alt="" className="w-6 h-6 rounded-full mr-[10px]" />}
        <span className="text-base-regular capitalize">{text}</span>
      </div>
      <FontAwesomeIcon icon={icon || faChevronDown} />
    </div>
  );
}

export default SSelectOpener;
