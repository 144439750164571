import { useTranslation } from "react-i18next";
import Flow from "../components/dashboard/flow/Flow";
import UpcomingPosts from "../components/dashboard/upcomingPosts/UpcomingPosts";
import { useEffect, useState } from "react";
import { useGetCurrentBrand } from "../api/brand/current/get";
import { useGetOnboardingInfo } from "../api/workspaces/onboardingInfo/get";
import MembersMap from "../components/dashboard/members/MembersMap";
import TeamActivity from "../components/dashboard/teamActivity/TeamActivity";
import { getQuery } from "../hooks/functions/queries";
import { useJoinWithGLobalLink } from "../api/invitations/invitationLink/post";
import { usePutSwitchTeam } from "../api/workspaces/swich/put";
import { toast } from "../hooks/toast";
import { InviteModal } from "../components/dashboard/modals/InviteModal";
import { GlobalInviteMessageModal } from "../components/dashboard/modals/GlobalInviteMessageModal";
import { useAccountStore } from "../store/accountStore";
import { OrganizationUserRole, TeamPackageStatus } from "../utils/enums/Enums";
import { BillingInfo } from "../components/billing/BillingInfo";
import { useSubscriptionActions } from "../hooks/useSubscriptionActions";
function Dashboard() {
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();
  const { data: onboardingInfo, isLoading: isOnboardingInfoLoading } =
    useGetOnboardingInfo();
  const [flowCompleted, setFlowCompleted] = useState(false);
  useEffect(() => {
    if (onboardingInfo) {
      const allStepsCompleted = Object.values(onboardingInfo.data).every(
        Boolean
      );
      setFlowCompleted(allStepsCompleted);
    }
  }, [onboardingInfo]);

  const code = getQuery("code");
  const isGlobal = getQuery("isGlobal");
  const [inviteModal, setInviteModal] = useState(
    code && !isGlobal ? true : false
  );
  const [globalInviteModal, setGlobalInviteModal] = useState(
    code && isGlobal ? true : false
  );
  const { mutateAsync: joinWorkspace } = useJoinWithGLobalLink();
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  useEffect(() => {
    const join = () => {
      joinWorkspace({ globalInvitationCode: code })
        .then((res) => {
          const id: number = parseInt(getQuery("workspaceId") || "0");
          switchWorkspace({ teamId: id }).then((res) =>
            setGlobalInviteModal(true)
          );
        })
        .catch((err) => {
          toast(err.message, "error");
        });
    };
    if (code && isGlobal) {
      join();
    }
  }, []);
  const { personalInfo } = useAccountStore();
  const { handleClick, currentPlan } = useSubscriptionActions();
  return (
    <div className="w-full py-[48px] max-md:gap-[24px]">
      {inviteModal && (
        <InviteModal
          inviteModal={inviteModal}
          setInviteModal={setInviteModal}
        />
      )}
      {currentPlan &&
        (currentPlan?.data.isTrial ||
          currentPlan?.data.status === TeamPackageStatus.CANCELED ||
          currentPlan?.data.status === TeamPackageStatus.PAUSED) && (
          <BillingInfo
            className="-translate-y-6"
            handleClick={handleClick}
            type={currentPlan?.data.status}
            isTrial={currentPlan?.data.isTrial}
          />
        )}
      {globalInviteModal && (
        <GlobalInviteMessageModal
          inviteModal={globalInviteModal}
          setInviteModal={setGlobalInviteModal}
        />
      )}
      <div className="w-full flex gap-[54px] max-md:flex-col">
        <div className="w-[45%] flex max-md:w-[100%]">
          {!flowCompleted &&
            personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
              <Flow
                isOnboardingInfoLoading={isOnboardingInfoLoading}
                onboardingInfo={onboardingInfo}
              />
            )}
          {(flowCompleted ||
            personalInfo?.userRole === OrganizationUserRole.VIEWER) && (
            <TeamActivity />
          )}
        </div>
        <div className="w-[65%] max-md:w-[100%]">
          <MembersMap />
        </div>
      </div>
      {!isCurrentBrandError && currentBrand && <UpcomingPosts />}
    </div>
  );
}

export default Dashboard;
