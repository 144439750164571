import { useRef, useState } from "react";
import { useDropbox } from "../../../hooks/useDropbox";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import {
  IFileCOE,
  IUploadFIleVM,
} from "../../../utils/interfaces/IAssets";
import { toast } from "../../../hooks/toast";
import { useLibraryStore } from "../../../store/libraryStore";

export const useLibraryDropbox = ({
  createFile,
  parentFolderId,
  isPending,
  uploadFileAdditionalFunction,
}: {
  uploadFileAdditionalFunction?: (res: IBaseData<IUploadFIleVM>) => void;
  createFile: UseMutateAsyncFunction<
    IBaseData<IUploadFIleVM>,
    Error,
    IFileCOE,
    unknown
  >;
  parentFolderId?: number | null;
  isPending: boolean;
}) => {
  const { setPage, setFoldersAndFiles } = useLibraryStore();
  const { dropboxSelector } = useDropbox();
  const dropboxRef = useRef<HTMLInputElement>(null);
  const [dropboxFiles, setDropboxFiles] = useState<any>(null);

  const onDropboxSelectSubmited = async (files: any) => {
    setDropboxFiles(files);
    let dropboxFormData = await dropboxSelector(files);
    createFile({ folderId: parentFolderId, files: dropboxFormData })
      .then((res) => {
        setPage(1);
        setFoldersAndFiles([]);
        uploadFileAdditionalFunction && uploadFileAdditionalFunction(res);
        toast("File uploaded successfully", "success");
      })
      .catch((err) => {
        toast(err.response && err.response.data.message, "error");
      });
  };

  return { dropboxRef, onDropboxSelectSubmited, dropboxFiles, setDropboxFiles };
};
