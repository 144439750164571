import { toast } from "../../../hooks/toast";
import { IRenameFileOrFolderProps } from "../hooks/useGridCardActions";

export interface RenameFileOrFolderProps {
  renameAsset: IRenameFileOrFolderProps | null;
  updateFolder: any;
  updateFile: any;
  value: string;
  setOpenRename: (value: boolean) => void;
  setValue: (value: string) => void;
}

export const renameFileOrFolder = ({
  renameAsset,
  updateFolder,
  updateFile,
  value,
  setOpenRename,
  setValue,
}: RenameFileOrFolderProps) => {
  if (renameAsset?.type === "Folder") {
    updateFolder({
      folderName: value,
      id: renameAsset?.id,
    })
      .then(() => {
        toast(`Folder renamed successfully`, "success");
      })
      .catch(() => {
        toast(`Folder rename failed`, "error");
      });
  } else {
    updateFile({
      id: renameAsset?.id || 0,
      data: {
        name: value,
        extension: renameAsset?.extension || "",
        pathInStorage: renameAsset?.path || "",
      },
    })
      .then(() => {
        toast(`Asset renamed successfully`, "success");
      })
      .catch(() => {
        toast(`Asset rename failed`, "error");
      });
  }
  setOpenRename(false);
  setValue("");
}