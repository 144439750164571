import React from "react";
import { usePostTermsOfServiceConfirmation } from "../api/auth/termsOfServiceConfirmation/post";
import { getQuery } from "../hooks/functions/queries";
import { useNavigate } from "react-router-dom";
import { toast } from "../hooks/toast";
//@ts-ignore
import logo from "../assets/images/logos/stori-s-square.svg";
import SButton from "../design-system/SButton";
function TermsConfirmation() {
  const code = getQuery("code");
  const userId = getQuery("userId");
  const {
    mutateAsync: confirmTerms,
    isSuccess,
    isPending,
  } = usePostTermsOfServiceConfirmation();
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (code && userId)
      confirmTerms({ code, userId }).then((res) => {
        toast("Succesfully confirmed", "success");
        navigate("/login");
      });
  };
  return (
    <div className="w-[100dvw] h-[100dvh] bg-primary relative ">
      <img
        src={logo.toString()}
        className="absolute top-[40px] left-[40px] md:hidden"
      />
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex flex-col items-center p-[48px] max-w-[500px] w-full shadow-[0_4px_24px_rgba(0,0,0,0.1)] bg-primary rounded-[32px] gap-[24px] md:p-[18px]">
          <img src={logo.toString()} className="hidden md:block" />
          <p className="text-[32px] font-semibold">
            {isSuccess ? "Accepted" : "Terms Of Service"}
          </p>
          {!isSuccess && (
            <div>
              <p className="text-center text-s leading-[18px] text-[13px]">
                By clicking "Accept", I agree to STORI AI's{" "}
                <a
                  href="https://storiai.com/doc/Terms.pdf"
                  className="text-[#28CCA7]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TOS
                </a>{" "}
                and{" "}
                <a
                  href="https://storiai.com/doc/privacy.pdf"
                  className="text-[#28CCA7]"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
              <p className="text-center text-s text-[13px] mt-[8px]">
                You'll need to log in again.
              </p>
            </div>
          )}
          {isSuccess ? (
            <a href="/login">
              <SButton onClick={() => navigate("/login")}>Login</SButton>
            </a>
          ) : (
            <SButton onClick={handleSubmit} disabled={isPending}>
              Accept
            </SButton>
          )}
        </div>
      </div>
    </div>
  );
}

export default TermsConfirmation;
