//@ts-ignore
import TURKISH from "../../../assets/icons/countries/TURKISH.svg";
//@ts-ignore
import FRENCH from "../../../assets/icons/countries/FRENCH.svg";
//@ts-ignore
import ITALIAN from "../../../assets/icons/countries/ITALIAN.svg";
//@ts-ignore
import JAPANESE from "../../../assets/icons/countries/JAPANESE.svg";
//@ts-ignore
import CHINESE from "../../../assets/icons/countries/CHINESE.svg";
//@ts-ignore
import GERMAN from "../../../assets/icons/countries/GERMAN.svg";
//@ts-ignore
import PORTUGUESE from "../../../assets/icons/countries/PORTUGUESE.svg";
//@ts-ignore
import POLISH from "../../../assets/icons/countries/POLISH.svg";
//@ts-ignore
import SWEDISH from "../../../assets/icons/countries/SWEDISH.svg";
//@ts-ignore
import DUTCH from "../../../assets/icons/countries/DUTCH.svg";
//@ts-ignore
import SPANISH from "../../../assets/icons/countries/SPANISH.svg";
//@ts-ignore
import ENGLISH from "../../../assets/icons/countries/ENGLISH.svg";

export const getCountryFlag = (country: string) => {
  const countryFlag: any = {
    TURKISH,
    FRENCH,
    ITALIAN,
    JAPANESE,
    CHINESE,
    GERMAN,
    PORTUGUESE,
    POLISH,
    SWEDISH,
    DUTCH,
    SPANISH,
    ENGLISH,
  }

  return countryFlag[country];

};
