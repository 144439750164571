import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LibraryOptionsButton } from "./LibraryOptionsButton";
import { ILibraryProps } from "../../pages/Library";
import { useRef } from "react";
import { faFolder } from "@fortawesome/pro-solid-svg-icons";

export const HorizontalFolders = ({
  setOpenConfirmation,
  cardType,
  cardTitle,
  imageSrc,
  isSelected = false,
  onClick,
  onDoubleClick,
  hasOptions = true,
  id,
  extension,
  pathInStorage,
  parentFolderId,
  handleDelete,
  handleDownload,
  handleFolderDownload,
  handleMoveOut,
  handleRename,
  handleMoveInto,
  isContent = false,
  isRoot,
}: ILibraryProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className={`${isSelected ? "border-brand-200 bg-brand-200 hover:bg-brand-200" : "bg-gray-25 border-gray-200 hover:bg-gray-100"} [&_button]:hover:inline cursor-default flex items-center justify-between select-none rounded-2xl border px-5 py-4`}
    >
      <div className="w-full flex items-center gap-3">
        <FontAwesomeIcon icon={faFolder} className="text-gray-500 text-lg" />
        <h1 title={cardTitle} className="text-md-m text-gray-900 truncate">
          {cardTitle}
        </h1>
      </div>
      {handleDownload && handleFolderDownload && handleRename && (
        <LibraryOptionsButton
          className="px-[8px] py-[0px]"
          id={id}
          isRoot={isRoot}
          isContent={isContent}
          cardType={cardType}
          cardTitle={cardTitle}
          imageSrc={imageSrc}
          hasOptions={hasOptions}
          dropdownRef={dropdownRef}
          handleDelete={(e) => {
            setOpenConfirmation && setOpenConfirmation(true);
            handleDelete && handleDelete({ e, id, cardType: "Folder" });
          }}
          handleRename={(e) => {
            handleRename &&
              handleRename({
                e,
                id,
                cardType: "Folder",
                path: pathInStorage,
                extension,
              });
          }}
          handleDownload={handleDownload}
          handleFolderDownload={(e) => {
            handleFolderDownload({ e, folderId: id });
          }}
          handleMoveOut={(e) =>
            handleMoveOut &&
            handleMoveOut({
              e,
              fileIds: cardType !== "Folder" ? [id] : [],
              folderIds: cardType === "Folder" ? [id] : [],
              parentId: parentFolderId,
            })
          }
          handleMoveInto={(e) =>
            handleMoveInto &&
            handleMoveInto({
              e,
              fileIds: cardType !== "Folder" ? [id] : [],
              folderIds: cardType === "Folder" ? [id] : [],
            })
          }
        />
      )}
    </div>
  );
};
