import React from "react";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { useAccountStore } from "../../../store/accountStore";
import { OrganizationUserRole } from "../../../utils/enums/Enums";
function Tagline() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const { personalInfo } = useAccountStore();
  return (
    <div className="px-[32px] pt-[20px] pb-[32px] bg-base-white border-b-[1px] border-b-gray-200 max-md:py-[16px] max-md:px-[20px]">
      <p className="text-md-sb text-gray-800 w-full text-left mb-[14px]">
        Tagline
      </p>
      {currentBrand?.isBrandDataGenerated &&
      currentBrand?.brandingData?.BrandVoice?.Tagline ? (
        <p
          className="mt-[20px] outline-none text-md-m text-graycool-600"
          contentEditable={personalInfo?.userRole !== OrganizationUserRole.VIEWER}
          onBlur={(e) => {
            if (
              currentBrand &&
              personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
              currentBrand?.brandingData?.BrandVoice?.Tagline !==
                e.currentTarget.innerText
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.BrandVoice.Tagline = e.currentTarget.innerText;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              });
            }
          }}
          onPaste={(event) => {
            event.preventDefault();
            const text = event.clipboardData.getData("text/plain");
            document.execCommand("insertText", false, text);
          }}
        >
          {currentBrand?.brandingData?.BrandVoice?.Tagline}
        </p>
      ) : (
        <div className="animate-pulse">
          <div className="w-full h-[16px] rounded-full bg-gray-200" />
          <div className="mt-[6px] w-[80%] h-[16px] rounded-full bg-gray-200" />
        </div>
      )}
    </div>
  );
}

export default Tagline;
