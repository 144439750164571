import React, { useEffect, useRef, useState } from "react";
import SBadge from "../../../../../design-system/SBadge";
import {
  faChevronDown,
  faSearch,
  faSparkles,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../../design-system/SButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreationStore } from "../../../../../store/creationStore";
import { useHelperInputHelperFunctions } from "./useHelperInputHelperFunctions";
import { useCreationLibraryHelperFunctions } from "../hooks/useCreationLibraryHelperFunctions";
import { ImageGenerationStyle } from "../../../../../utils/enums/Enums";
import { toast } from "../../../../../hooks/toast";
import { useGetWorkspaceUsage } from "../../../../../api/workspaces/limits/get";

function Input({
  isOpen,
  setIsOpen,
  activeTab,
  setActiveTab,
  setInputValue,
  inputValue,
  activeStyleTag,
}: {
  activeStyleTag: ImageGenerationStyle;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: "generator" | "stocks" | "search" | "selector";
  setActiveTab: React.Dispatch<
    React.SetStateAction<"generator" | "stocks" | "search" | "selector">
  >;
}) {
  const { handleGenerateImage } = useCreationLibraryHelperFunctions();
  const { setLibrarySearchValue } = useCreationStore();
  const { handleSearchStocks } = useHelperInputHelperFunctions();
  const inputRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  return (
    <div
      className={`${isOpen && "mx-[24px]"} transition-all duration-500 ease-in-out`}
      onClick={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
    >
      <div
        className={`flex w-full border items-center border-gray-200 bg-gray-100 rounded-[8px] overflow-hidden`}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (activeTab === "generator" || activeTab === "selector") {
              setActiveTab("stocks");
            }
            if (activeTab === "stocks") {
              setActiveTab("search");
            }
            if (activeTab === "search") {
              setActiveTab("generator");
            }
          }}
          className="min-w-[180px] bg-base-white flex items-center justify-center shadow-sm p-[8px]"
        >
          <SBadge
            hasBackground={false}
            size="lg"
            icon={faChevronDown}
            iconLeft={false}
            className="cursor-pointer"
          >
            {(activeTab === "generator" || activeTab === "selector") &&
              "🪄 Generator"}
            {activeTab === "stocks" && "🖼️ Stock Images"}
            {activeTab === "search" && "🔎 Search"}
          </SBadge>
        </div>
        <input
          ref={inputRef}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              if (activeTab === "search") {
                inputRef.current.blur();
                setLibrarySearchValue(inputValue);
                setIsOpen(false);
              }
              if (activeTab === "stocks") {
                handleSearchStocks(inputValue);
              }
              if (
                !isLoading &&
                (activeTab === "generator" || activeTab === "selector")
              ) {
                if (inputValue.length > 0) {
                  setIsLoading(true);
                  handleGenerateImage({
                    prompt: inputValue,
                    style: activeStyleTag,
                  })
                    .then(() => {
                      setActiveTab("selector");
                    })
                    .finally(() => setIsLoading(false));
                }
              }
            }
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="px-[16px] py-[10px] text-sm border-0 w-full outline-none focus:outline-0 focus:ring-0 text-md placeholder:text-gray-500 text-gray-900 bg-transparent"
          placeholder={
            activeTab === "generator" || activeTab === "selector"
              ? "Enter your prompt here"
              : activeTab === "search"
                ? "Search in your library"
                : "Search images"
          }
        />
        {isOpen && (
          <>
            {(activeTab === "generator" || activeTab === "selector") && (
              <SButton
                isLoading={isLoading}
                // lIconProps={{

                // }}
                onClick={() => {
                  if (!workspaceUsage?.data.canUserGenerateImages) {
                    toast(
                      "You have reached the limit of generations for your workspace.",
                      "error"
                    );
                    return;
                  }
                  if (inputValue.length > 0 && !isLoading) {
                    setIsLoading(true);
                    handleGenerateImage({
                      prompt: inputValue,
                      style: activeStyleTag,
                    })
                      .then((res) => {
                        toast("Image generated", "success");
                        setActiveTab("selector");
                      })
                      .catch((err) => {
                        console.log("123", err);
                        toast(err.response.data.message, "error");
                      })
                      .finally(() => setIsLoading(false));
                  }
                }}
                size="sm"
                className="mr-[4px]"
                lIcon={faSparkles}
              >
                Generate
              </SButton>
            )}
            {activeTab === "search" && (
              <div className="flex items-center gap-[12px]">
                <FontAwesomeIcon
                  onClick={() => {
                    setInputValue("");
                    setLibrarySearchValue("");
                    setIsOpen(false);
                  }}
                  icon={faX}
                  className="text-[10px] cursor-pointer text-gray-600"
                />
                <SButton
                  onClick={() => {
                    setLibrarySearchValue(inputValue);
                    setIsOpen(false);
                  }}
                  isLoading={isLoading}
                  size="sm"
                  type="secondaryGray"
                  className="mr-[4px]"
                  lIcon={faSearch}
                >
                  Search
                </SButton>
              </div>
            )}
            {activeTab === "stocks" && (
              <SButton
                onClick={() => {
                  handleSearchStocks(inputValue);
                }}
                size="sm"
                isLoading={isLoading}
                type="secondaryGray"
                className="mr-[4px]"
                lIcon={faSearch}
              >
                Search
              </SButton>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Input;
