import React from "react";
import FrameViewItem from "./FrameViewItem";

function ImageSelectorItem({ frameImage }: { frameImage?: string }) {
  return (
    <div className="w-[152px] min-h-[152px] relative aspect-square">
      <img
        className="w-[124px] h-[124px] object-cover absolute top-0 left-0 rounded-[12px]"
        src="https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg"
        alt=""
      />
      <FrameViewItem
        isDashed={false}
        isActive={false}
        image={frameImage}
        className="absolute bottom-0 right-0 w-[112px] h-[112px]"
      />
    </div>
  );
}

export default ImageSelectorItem;
