import { useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { ITermsOfServiceConfirmationCOE } from "../../../utils/interfaces/IAccount";
import { IBase } from "../../../utils/interfaces/IBase";

export const usePostTermsOfServiceConfirmation = () => {
  return useMutation<IBase<null>, Error, ITermsOfServiceConfirmationCOE>({
    mutationFn: (data) =>
      makeRequest.post(
        `/User/TermsOfServiceConfirmation?userId=${data.userId}&code=${data.code}`
      ),
  });
};
